/* eslint-disable */
import React from 'react';
import {cn} from '../../utils/cn';
import {InputT} from './InputT';
export interface TextInputT extends InputT {
  textarea?: boolean;
  defaultValue?: string;
  state: [string, React.Dispatch<React.SetStateAction<string>>];
}
// #707895
const TextInput: React.FC<TextInputT> = (props) => {
  const {label, placeholder, state, textarea, className, defaultValue} = props;
  return (
    <div className={cn('', className)}>
      {label?.length && (
        <p
          className="mt-2"
          style={{
            color: '#707895',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '19px',
          }}
        >
          {label}
        </p>
      )}
      {!textarea ? (
        <label htmlFor="input">
          <input
            type="text"
            id="input"
            className={'!mt-2'}
            style={{
              borderRadius: '8px',
              border: '1px solid rgba(29, 54, 83, 0.17)',
              background: '#FAFCFF',
              padding: '15px 16px',
              maxHeight: '30px',
              color: 'black',
            }}
            placeholder={placeholder}
            value={state[0]}
            defaultValue={defaultValue}
            onChange={(e) => state[1](e.target.value)}
          />
        </label>
      ) : (
        <label htmlFor="text-area">
          <textarea
            id="text-area"
            className={cn('!mt-2', className)}
            style={{
              borderRadius: '8px',
              border:
                '1px solid var(--elements-seporator, rgba(29, 54, 83, 0.17))',
              background: '#FAFCFF',
              padding: '15px 16px',
              height: '150px',
            }}
            placeholder={placeholder}
            value={state[0]}
            cols={30}
            rows={30}
            onChange={(e) => state[1](e.target.value)}
          />
        </label>
      )}
    </div>
  );
};

export default TextInput;
