import React from 'react';
import Forms from '../../../pages/landing/Main/Forms';
import {Box, IconButton, Modal} from '@mui/material';
import IModal from '../../../pages/landing/types/IModal';
import closeIcon from '../../../static/landing/addcircle.svg';
import s from './formsmodal.module.scss';

const FormsModal: React.FC<IModal> = ({open, handleClose, handleIconClose}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={s.wrapper}>
        <IconButton
          sx={{
            right: 0,
            top: 0,
            marginRight: '5px',
            marginBottom: '3px',
            position: 'absolute',
          }}
          onClick={handleIconClose}
        >
          <img src={closeIcon} alt="" />
        </IconButton>
        <Forms isMobile />
      </Box>
    </Modal>
  );
};

export default FormsModal;
