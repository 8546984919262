import {createSlice} from '@reduxjs/toolkit';
import type {RootStateT} from '../store';

interface CartItemI {
    id: string,
    name: string,
    companyName: string,
    price: number,
}

interface CartStateT {
  items: CartItemI[],
}

const initialState: CartStateT = {
  items: [],
};

const CartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
  },

});

const CartReducer = CartSlice.reducer;

export const CartActions = CartSlice.actions;
export const items = (state: RootStateT) => state.CartReducer.items;

export default CartReducer;
