/* eslint-disable */
import React, {useCallback, useRef, useState} from 'react';
import {cn} from '../../utils/cn';
import {InputT} from '../NewInputs/InputT';
import importFile from '../../static/productpage/importFile.svg';

interface DragAndDropPropsT extends InputT {
  state: [string[], React.Dispatch<React.SetStateAction<string[]>>];
  innerImage: string;
  innerText: string;
  type: string;
}

const DragAndDrop: React.FC<DragAndDropPropsT> = ({
  state,
  innerImage,
  innerText,
  label,
  type,
}) => {
  const [highlight, setHighlight] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onDragOver = useCallback((e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setHighlight(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setHighlight(false);
  }, []);

  const onDrop = useCallback((e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setHighlight(false);

    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  }, []);

  const handleFiles = (files: File[]) => {
    const filePromises: Promise<string>[] = [];

    files.forEach((file) => {
      filePromises.push(
        new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = () => {
            if (typeof reader.result === 'string') {
              resolve(reader.result);
            }
          };

          reader.readAsDataURL(file);
        }),
      );
    });

    Promise.all(filePromises).then((base64Strings) => {
      state[1](state[0].concat(base64Strings));
    });
  };

  const handleClick = () => {
    // if (fileInputRef.current) {         // повторный клик появляется из-за этой функции
    //   fileInputRef.current.click();
    // }
  };
  // console.log(fileInputRef);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      handleFiles(files);
    }
  };

  return (
    <>
      {label?.length && (
        <p
          className="my-2"
          style={{
            color: '#707895',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '19px',
          }}
        >
          {label}
        </p>
      )}
      <label
        className={cn(
          highlight ? '!border-4' : '',
          'flex flex-col justify-center items-center px-[160px] py-[60px] transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none',
        )}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={handleClick}
      >
        {highlight ? (
          <div className="w-[90px] h-[90px]" />
        ) : (
          <img
            src={state[0].length > 0 ? importFile : innerImage}
            alt="drag-and-drop-pic"
            className="w-[90px] h-[90px]"
          />
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{display: 'none'}}
          onChange={handleInputChange}
        />
        {state[0].length > 0 ? (
          <p
            className="text-center"
            style={{color: '#707895', fontSize: '14px', fontWeight: '500'}}
          >
            {
              (type = 'file'
                ? `Загружен ${fileInputRef?.current?.files?.item(0)?.name}`
                : `Файлов загружено: ${state[0].length}`)
            }
          </p>
        ) : (
          <p
            className="text-center"
            style={{color: '#707895', fontSize: '14px', fontWeight: '500'}}
          >
            {innerText}
          </p>
        )}
      </label>
    </>
  );
};

export default DragAndDrop;
