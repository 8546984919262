import {DefaultHttpCache} from './http-cache';
import mockedNetCall from './mock';
import mockMap from './mockMap';
import {ajaxDebug, MOCK, APIurl} from '../utils/globals';

import store from '../redux/store';
import {MessageTypesT} from './messageTypes';

/* eslint-disable no-undef */
// интерфейсы RequestInfo и RequestInit - типы параметров fetch


// CSRF guard
// const CSRFCookieName = '_csrf';

type AjaxMethodT = 'POST' | 'GET' | 'PUT';
type AjaxMethodsT = {
  [key: string]: AjaxMethodT,
}

const ajaxMethods: AjaxMethodsT = {
  post: 'POST',
  get: 'GET',
  put: 'PUT',
};

/**
 * Поддерживаемые http-статусы ответа: 200, 400, 404
 */
const ajaxStatuses = {
  ok: 200,
  notFound: 404,
  redirect: 303,
  badRequest: 400,
  invalidSession: 424,
} as const;

const MSG_FORMAT_ERROR = new Error('Нарушен формат сообщения');

interface RequestParamsNoMethodI {
  url: RequestInfo,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any,
  headers?: Record<string, string>,
  useCache?: boolean,
}

interface RequestParamsI extends RequestParamsNoMethodI {
  method: AjaxMethodT,
}

interface AjaxResponse {
  status: number,
  response: {
    data: unknown,
    msg: string,
    status: number,
  },
}

// TODO: написать доку под кэш
const httpCache = new DefaultHttpCache();

/**
 * Выполняет ajax-запрос на сервер. При успешном выполнении вызывает callback
 * @param {RequestParamsI} requestParams
 * @property {AjaxMethod} [method = 'GET']
 * @property {Url} [url = '/']
 * @property {object} body
 * @param {boolean} useCache
 * @return {Promise}
 */
function ajax(requestParams: RequestParamsI, useCache = false): Promise<AjaxResponse> {
  const {method, headers = {}, body} = requestParams;
  const url = APIurl + (requestParams.url || '/');

  // Auth
  const token = store.getState().AuthReducer.token;
  // if (token && requestParams.body !== undefined) {
  //   Object.assign(requestParams.body, {token});
  // }

  // CSRF guard
  // const csrf = document.cookie.split(';')
  //     .map((c) => c.trim())
  //     .find((c) => c.startsWith(CSRFCookieName + '='))
  //     ?.substring(CSRFCookieName.length + 1);  // skip '_csrf='
  if (!('Content-Type' in headers)) {
    headers['Content-Type'] = 'application/json';
  }
  // Object.assign(headers, {
  //   // 'X-XSRF-TOKEN': csrf,
  // });

  if (token) {
    Object.assign(headers, {'Authorization': `Token ${token}`});
  }

  const fetchParams: RequestInit = {
    body: (Array.isArray(body) || typeof body === 'object') ? JSON.stringify(body) : body,
    mode: 'cors',
    credentials: 'include',
    headers,
    method,
  };

  let cacheKey: string;
  if (useCache) {
    cacheKey = httpCache.serializeCacheKey({url, requestParams: fetchParams});
    const cachedData = httpCache.get(cacheKey);
    if (cachedData) {
      if (ajaxDebug) console.log('[AJAX] cache:', cachedData);
      return (async () => cachedData)();
    }
  }


  if (ajaxDebug) {
    console.log('[AJAX] request', {url}, ': ', {body: requestParams.body});
  }

  if (MOCK) {
    console.warn('[AJAX] MOCK is true! > see utils/globals.ts');
    return mockedNetCall(mockMap[requestParams.url as MessageTypesT || '/'] || 'mocked message');
  }

  let status = 0;


  return fetch(url, fetchParams)
    .then((response: Response) => {
      status = response.status;
      // нельзя сначала распарсить через .text(), а потом через .json()
      // .json() на пустом теле дает ошибку. JSON.parse('') дает {}
      return response.text()
        .then((text: string) => {
          try {
            const res = JSON.parse(text);
            if (!('data' in res) && !('msg' in res)) {
              throw MSG_FORMAT_ERROR;
            }
            if (ajaxDebug) {
              console.log('[AJAX] resolved ' + status + ':', res);
            }
            return {
              status,
              response: res,
            };
          } catch (error: unknown) {
            if (error === MSG_FORMAT_ERROR) {
              console.log('[AJAX]', error);
            } else if ((error as Error).message === 'Unexpected end of JSON input') {
              // ignore
            } else {
              console.warn(error);
            }
          }
          if (ajaxDebug) {
            console.log('[AJAX] resolved ' + status + ':', text);
          }
          return {
            status,
            response: {
              msg: text,
              data: {},
              status,
            },
          };
        });
    })
    .then((obj) => {
      if (obj.status === ajaxStatuses.ok) {
        httpCache?.set(cacheKey, obj);
      }
      return obj;
    })
    .catch((error: Error) => {
      console.warn(error);
    }) as Promise<AjaxResponse>;
}

/**
 * Выполняет отправку фото на сервер. При успешном выполнении вызывает callback
 * @param {Object} requestParams
 * @property {Url} [url = '/']
 * @property {any} body
 * @return {Promise}
 */
function postFile(requestParams: RequestParamsNoMethodI): Promise<AjaxResponse> {
  const url = APIurl + (requestParams.url || '/');
  const formData = new FormData();
  formData.append('img', requestParams.body as Blob);
  const fetchParams: RequestInit = {
    body: formData,
    mode: 'cors',
    credentials: 'include',
    method: ajaxMethods.post,
  };

  if (ajaxDebug) {
    console.log('[AJAX] file post request', {url},
      ': ', {body: fetchParams.body});
  }

  let status = 0;
  return fetch(url, fetchParams)
    .then((response) => {
      status = response.status;
      return response.json();
    })
    .then((response) => {
      if (ajaxDebug) {
        console.log('[AJAX] resolved ' + status + ': ');
        console.log(response);
      }
      return {
        status,
        response,
      };
    })
    .catch((error: Error) => {
      console.warn(error);
    }) as Promise<AjaxResponse>;
}

// Плагин для общения с API
const Ajax = {
  AJAX_METHODS: ajaxMethods,
  STATUS: ajaxStatuses,
  get: (requestParams: RequestParamsNoMethodI, useCache?: boolean) =>
    ajax({method: ajaxMethods.get, ...requestParams}, useCache),
  post: (requestParams: RequestParamsNoMethodI, useCache?: boolean) =>
    ajax({method: ajaxMethods.post, ...requestParams}, useCache),
  put: (requestParams: RequestParamsNoMethodI, useCache?: boolean) =>
    ajax({method: ajaxMethods.put, ...requestParams}, useCache),
  postFile,
  APIurl,
};

export default Ajax;
