/* eslint-disable react/no-unescaped-entities */
import {Box, Stack, Typography, Rating} from '@mui/material';
import feedbackUber from '../../../static/imgs/feedback_uber.svg';
import defaultImg from '../../../images/default.png';
import React from 'react';

export default function Feedback() {
  const [value, setValue] = React.useState<number | null>(4);
  return (
    <Box
      sx={{
        width: '100%',
        margin: '30px auto',
      }}
    >
      <Stack
        sx={{
          width: '1244px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '33px',
          padding: '70px 0 65px',
        }}
      >
        <Typography
          fontSize={'36px'}
          fontWeight={'600'}
          sx={{color: '#272121'}}
        >
          Отзывы о нас
        </Typography>
        <Stack display={'flex'} flexDirection={'row'} gap="15px">
          <Box
            sx={{
              width: '720px',
              height: '376px',
              padding: '40px 30px',
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
              borderRadius: '8px',
              border: '1px solid #E6EAF7',
            }}
          >
            <Stack display={'flex'} flexDirection={'row'} gap="8px">
              <img
                src={defaultImg}
                alt="логотип компании"
                style={{width: '86px', height: '86px', borderRadius: '50%'}}
              />
              <Stack display={'flex'} flexDirection={'column'} gap="8px">
                <Typography fontSize={'24px'} fontWeight={'600'}>
                  Компания "Стройтех"
                </Typography>
                <Rating name="read-only" value={5} readOnly />
              </Stack>
            </Stack>
            <Typography fontSize={'16px'} fontWeight={'400'}>
              Недавно воспользовался этой платформой для покупки строительных
              материалов, и мой опыт был превосходным! Быстрый чат с
              поставщиками позволил мне мгновенно получить всю необходимую
              информацию и сделать выбор, не тратя лишнее время на поиски и
              ожидание ответов. Доставка тоже прошла гладко и без задержек, а
              качество материалов полностью соответствовало моим ожиданиям.
              Очень доволен тем, что нашел надежного поставщика через эту
              платформу. Рекомендую всем, кто ценит свое время и качество!
            </Typography>
          </Box>
          <Box
            sx={{
              width: '720px',
              height: '376px',
              padding: '40px 30px',
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
              borderRadius: '8px',
              border: '1px solid #E6EAF7',
            }}
          >
            <Stack display={'flex'} flexDirection={'row'} gap="8px">
              <img
                src={defaultImg}
                alt="логотип компании"
                style={{width: '86px', height: '86px', borderRadius: '50%'}}
              />
              <Stack display={'flex'} flexDirection={'column'} gap="8px">
                <Typography fontSize={'24px'} fontWeight={'600'}>
                  Иванов
                </Typography>
                <Rating name="read-only" value={value} readOnly />
              </Stack>
            </Stack>
            <Typography fontSize={'16px'} fontWeight={'400'}>
              Я в восторге от этой платформы объявлений строительных материалов!
              Впервые мне не пришлось бесконечно звонить и сравнивать цены и
              условия разных поставщиков – все, что мне было нужно, я нашел
              прямо здесь, благодаря удобному чату с поставщиками. Особенно
              порадовала оперативная доставка, которая значительно ускорила
              процесс моего ремонта. Также хочу отметить высокое качество
              продукции и надежность поставщика, выбранного через платформу. Это
              был невероятно удобный и эффективный опыт покупки строительных
              материалов!
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
