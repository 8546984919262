import React, {useEffect} from 'react';
import RootPage from '../rootPage';
import ChatList from './ChatList';
import TitleChat from './TitleChat';
import WindowChat from './WindowChat';
import {Box, Grid, Typography} from '@mui/material';
import useDebounce from '../../hooks/useDebounce';
import Ajax from '../../api/ajax';
import {currentUserType, ChatData} from './types';
import {useLocation} from 'react-router';
import {
  getChatListAction,
  changeStatusListAction,
} from '../../redux/reducers/chatListReducer';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';

const Chats: React.FC = () => {
  const [chats, setChats] = React.useState<ChatData[]>([]); // для чатов с сервера
  const [fetchingUser, setFetchingUser] = React.useState(true);
  const [currentUser, setCurrentUser] = React.useState<any>();
  const debouncedSearchReq = useDebounce('NULL', 500);
  const [isLoading, setIsLoading] = React.useState(false);
  const {isNewChat} = useAppSelector((state) => state.chatListReducer);
  const dispatch = useAppDispatch();

  const location = useLocation().pathname;
  useEffect(() => {
    if (debouncedSearchReq !== '') {
      Ajax.get({
        url: `/allchats`,
      })
        .then(({status, response}) => {
          if (status === Ajax.STATUS.ok) {
            setChats(response.data as ChatData[]);
            dispatch(getChatListAction(response.data));
          } else {
            console.warn(response.msg);
          }
        })
        .finally(() => {
          console.log(chats);
          dispatch(changeStatusListAction(false));
          setIsLoading(true);
        });
    }
  }, [isNewChat, dispatch]);

  useEffect(() => {
    if (fetchingUser) {
      if (debouncedSearchReq !== '') {
        setIsLoading(true);
        Ajax.get({
          url: `/user/me`,
        })
          .then(({status, response}) => {
            if (status === Ajax.STATUS.ok) {
              setCurrentUser(response.data as currentUserType);
              setIsLoading(false);
              console.log('User new request');
            }
          })
          .finally(() => setFetchingUser(false));
      }
    }
  }, [fetchingUser, debouncedSearchReq]);

  return (
    <RootPage nameOfActive="Chat">
      <Box sx={{margin: '20px 30px'}}>
        <Grid sx={{display: 'flex', gap: '15px'}}>
          {/* {isLoading ? <ChatList lastMsgList={chats} /> : null} */}
          <ChatList lastMsgList={chats} />
          <Box
            className="chats__area"
            sx={{
              backgroundColor: 'white',
              width: '100%',
              borderRadius: '12px',
              boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.07)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {location == '/chat' ? (
              <Box
                sx={{
                  width: '100%',
                  height: '70vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignIitems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={{display: 'flex', justifyContent: 'center'}}>
                  Выберите чат
                </Typography>
              </Box>
            ) : (
              <>
                <TitleChat dataChat={chats} />
                <WindowChat currentUser={currentUser} dataChat={chats} />
              </>
            )}
          </Box>
        </Grid>
      </Box>
    </RootPage>
  );
};

export default Chats;
