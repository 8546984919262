import React from 'react';
import Header from '../../components/header/headerComponent';
import Slogan from '../../components/slogan';
import Main from './Main';
import Info from './Info';
import AuthField from '../../components/AuthField';
import News from './News';
import Footer from '../../components/Footer';
import CardsInfo from './CardsInfo';
import Partners from './Partners';
import Feedback from './Feedback';

const HomePage: React.FC = () => {
  return (
    <>
      <Header />
      <Slogan />
      <Main />
      {/* <Info /> */}
      {/* <CardsInfo /> */}
      <Partners />
      <Feedback />
      {/* <AuthField /> */}
      <News />
      <Footer />
    </>
  );
};

export default HomePage;
