/* eslint-disable */
import {createSlice} from '@reduxjs/toolkit';
import {MockedCategoriesArr} from '../../utils/mockedCategoriesArr';

export type CompanyType = {
  id: number;
  name: string;
  description: string;
  legal_name: string;
  itn: string;
  psrn: string;
  address: string;
  legal_address: string;
  email: string;
  phone: string;
  link: string;
  activity: string;
  owner_id: number;
  rating: number;
  verified: number;
};
export type GoodT = {
  id: number;
  name: string;
  description: {
    String: string;
    Valid: boolean;
  };
  price: number;
  photo: string[] | null;
  docs: string[] | null;
  category_id: number;
  category_name: string;
  company: CompanyType;
};

type InitStateT = {
  category: string;
  subCatArr: number[];
  goods: GoodT[];
};

let initialState: InitStateT = {
  category: 'initial',
  subCatArr: [],
  goods: [],
};

const categorySlice = createSlice({
  name: 'initial',
  initialState,
  reducers: {
    changeCategory: (state, action) => {
      state.category = action.payload;
      //@ts-ignore
      state.subCatArr = MockedCategoriesArr[state.category];
    },
    changeSubCat: (state, action) => {
      state.subCatArr = action.payload;
    },
    changeCurrGoods: (state, action) => {
      state.goods = action.payload;
    },
    setCurrentGoodsArray: (state, action) => {
      state.goods.push(...action.payload);
    },
    flushGoodsArray: (state) => {
      console.log('flushGoodsArray');
      state = initialState;
    },
  },
});
export const {reducer: categoryReducer, actions: categoryActions} =
  categorySlice;
export const {
  changeCategory,
  changeSubCat,
  changeCurrGoods,
  setCurrentGoodsArray,
  flushGoodsArray,
} = categorySlice.actions;
