/* eslint-disable */
import Box from '@mui/material/Box';
import React from 'react';

const Slogan: React.FC = () => {
  return (
    <Box
      sx={{
        minWidth: '100vw',
        backgroundColor: '#375BA2',
        height: '56px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '20px',
        textAlign: 'center',
      }}
    >
      <h6
        onClick={() => (window.location.href = 'https://fasie.ru')}
        className="cursor-pointer"
      >
        Проект выполнен при поддержке «Фонда содействия инновациям» в рамках
        федерального проекта «Платформа университетского технологического
        предпринимательства
      </h6>
    </Box>
  );
};

export default Slogan;
