import React from 'react';
import s from './landing.module.scss';
import {Box, Grid, useMediaQuery} from '@mui/material';
import Info from './Info';
import Stats from './Stats';
import Forms from './Forms';
import LandingCarousel from '../../../components/landingCarousel/landingCarouselComponent';

const Main: React.FC = () => {
  const matches = useMediaQuery('(max-width: 1270px)');
  const matchesMobile = useMediaQuery('(max-width: 700px)');
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <Box component="main" className={s.main}>
          <Box className={s.inner_main}>
            <Box component="section" className={s.left}>
              <Info />
              <Stats />
            </Box>
            {matches ? null : (
              <Box component="section" className={s.right}>
                <Forms isMobile={false} />
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {!matchesMobile && (
        <Grid
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box component="main" className={s.main}>
            <Box className={s.inner_main}>
              <LandingCarousel />
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default Main;
