import * as React from 'react';
import {useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface Column {
  id: 'code' | 'client' | 'order' | 'date' | 'status';
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {id: 'client', label: 'Заказчик', minWidth: 170},
  {id: 'order', label: 'Заказ', minWidth: 170},
  {id: 'date', label: 'Дата доставки', minWidth: 170},
  {id: 'status', label: 'Статус', minWidth: 170, align: 'center'},
];

interface Data {
  code: number;
  client: string;
  order: string;
  date: string;
  status: boolean;
}

function createData(
  code: number,
  client: string,
  order: string,
  date: string,
  status: boolean,
): Data {
  return {code, client, order, date, status};
}

function getRandomDate() {
  const startOfYear = new Date('2022-01-01');
  const endOfYear = new Date('2022-12-31');
  const randomTime =
    startOfYear.getTime() +
    Math.random() * (endOfYear.getTime() - startOfYear.getTime());
  const randomDate = new Date(randomTime);

  // Define Russian month names
  const monthNames = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  // Get day, month, and year from the random date
  const day = randomDate.getDate();
  const month = randomDate.getMonth();
  const year = randomDate.getFullYear();

  // Create Russian language date string
  const russianDateString = `${day} ${monthNames[month]} ${year}`;

  return russianDateString; // Return the Russian language date string
}

const BlueText = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <Typography
        variant="h5"
        color="primary"
        style={{
          display: 'inline',
          fontWeight: 'bold',
          margin: '10px',
          marginTop: '20px',
          padding: '10px',
        }}
      >
        Смотреть все &gt;
      </Typography>
    </div>
  );
};

const rows = [
  createData(
    1,
    'ООО Вит***я',
    'Оптовый заказ штукатурки - 100 кг',
    getRandomDate(),
    true,
  ),
  createData(
    2,
    'ООО "Лам***икс"',
    'Заказ грунтовки - 50 л',
    getRandomDate(),
    true,
  ),
  createData(
    3,
    'ООО Группа Компаний "Ар***а"',
    'Оптовая закупка бетоносмесителей - 500 шт',
    getRandomDate(),
    false,
  ),
  createData(
    4,
    'ООО Пари***т99',
    'Заказ цемента - 20 кг',
    getRandomDate(),
    true,
  ),
  createData(
    5,
    'Аг***ойПанель, ООО, производственная компания',
    'Оптовый заказ пексобетона - 200 кг',
    getRandomDate(),
    false,
  ),
  createData(
    6,
    'ЖелезоСт***й',
    'Заказ клей для газоблоков - 50 кг',
    getRandomDate(),
    true,
  ),
  createData(
    7,
    'Велес Груп***ал, торгово-производственная компания',
    'Оптовый заказ стяжки пола - 30 кг',
    getRandomDate(),
    true,
  ),
  createData(
    8,
    'ООО"БИЗ*** ВИН"',
    'Заказ штукатурки гипсовой - 45 кг',
    getRandomDate(),
    true,
  ),
  createData(
    9,
    'ООО МОНТ***ЕПЛОСТ',
    'Оптовый заказ стяжки пола - 10 кг',
    getRandomDate(),
    true,
  ),
  createData(
    10,
    'ВосточныйСтройКом***кт',
    'Заказ шпаклевки - 200 кг',
    getRandomDate(),
    true,
  ),
  createData(
    11,
    'ООО Си***СМ',
    'Оптовый заказ растворителя от краски - 5 кг',
    getRandomDate(),
    false,
  ),
  createData(
    12,
    'СтройТе***ка',
    'Заказ шуроповертов - 20 штук',
    getRandomDate(),
    true,
  ),
  createData(
    13,
    'ООО "Инг***ом"',
    'Оптовый заказ цемента - 50 кг',
    getRandomDate(),
    true,
  ),
  createData(
    14,
    'Север строй,торговая фирма (ООО "С***Т")',
    'Заказ ремонтного состава - 10 кг',
    getRandomDate(),
    true,
  ),
  createData(
    15,
    'ООО МК СТАЛЬ-***ЙД',
    'Оптовый заказ грунта - 30 кг',
    getRandomDate(),
    true,
  ),
  createData(
    16,
    'ТПК Центр Ме***овли (ИП Да***н РР)',
    'Заказ затирки для камня - 100 кг',
    getRandomDate(),
    true,
  ),
  createData(
    17,
    'Ка***ныйДом',
    'Оптовый заказ камня для облицовки - 500 кг',
    getRandomDate(),
    false,
  ),
  createData(18, 'СтройМа***р', 'Заказ песка - 50 кг', getRandomDate(), true),
  createData(
    19,
    'СибС***',
    'Оптовый заказ гипсокартона - 100 шт',
    getRandomDate(),
    false,
  ),
  createData(
    20,
    'Ме***Трейд',
    'Заказ металлоконструкций - 5 тонн',
    getRandomDate(),
    true,
  ),
  createData(
    21,
    'Дом***й',
    'Оптовый заказ дверей - 20 штук',
    getRandomDate(),
    true,
  ),
  createData(
    22,
    'ООО "Лам***икс"',
    'Заказ профильных труб - 200 м',
    getRandomDate(),
    false,
  ),
  createData(
    23,
    'ООО МОНТ***ЕПЛОСТ',
    'Оптовый заказ бетона - 10 куб. м',
    getRandomDate(),
    true,
  ),
  createData(
    24,
    'Реко***кция',
    'Заказ керамической плитки - 100 кв. м',
    getRandomDate(),
    true,
  ),
  createData(
    25,
    'ООО "Инг***ом"',
    'Оптовый заказ кровельного материала - 50 кв. м',
    getRandomDate(),
    true,
  ),
  createData(
    26,
    'Теп***м',
    'Заказ теплоизоляционного материала - 500 кг',
    getRandomDate(),
    true,
  ),
  createData(
    27,
    'СтройМ***к',
    'Оптовый заказ керамической плитки - 100 м²',
    getRandomDate(),
    false,
  ),
  createData(
    28,
    'СтройК***',
    'Заказ строительной сетки - 20 рулонов',
    getRandomDate(),
    true,
  ),
  createData(
    29,
    'ООО "Инг***ом"',
    'Оптовый заказ гипсокартона - 50 листов',
    getRandomDate(),
    true,
  ),
  createData(
    30,
    'Кирпичн***Партнер',
    'Заказ кирпича керамического - 500 шт',
    getRandomDate(),
    true,
  ),
  createData(
    31,
    'Стальн***Штрипс',
    'Оптовый заказ металлической стружки - 100 кг',
    getRandomDate(),
    false,
  ),
  createData(
    32,
    'Монтажн***Сервис',
    'Заказ сварочного аппарата - 2 штуки',
    getRandomDate(),
    true,
  ),
  createData(
    33,
    'СтройП***ект',
    'Оптовый заказ герметика для окон - 30 штук',
    getRandomDate(),
    true,
  ),
  createData(
    34,
    'КамнеМ***',
    'Заказ камня декоративного - 50 кг',
    getRandomDate(),
    true,
  ),
  createData(
    35,
    'СтройТ***ка',
    'Оптовый заказ пена монтажная - 10 баллонов',
    getRandomDate(),
    true,
  ),
  createData(
    36,
    'ООО МОНТ***ЕПЛОСТ',
    'Заказ уголка металлического - 20 штук',
    getRandomDate(),
    true,
  ),
  createData(
    37,
    'ООО "Строй***материалы"',
    'Покупка кирпичей - 5000 штук',
    getRandomDate(),
    true,
  ),
  createData(
    38,
    'ООО "Строй***ресурс"',
    'Покупка песка - 10 тонн',
    getRandomDate(),
    false,
  ),
  createData(
    39,
    'ООО "Инг***ом"',
    'Покупка гипсокартона - 100 листов',
    getRandomDate(),
    true,
  ),
  createData(
    40,
    'ООО "Строй***"',
    'Закупка цемента - 50 мешков',
    getRandomDate(),
    true,
  ),
  createData(
    41,
    'ООО "Строй***"',
    'Покупка керамической плитки - 200 квадратных метров',
    getRandomDate(),
    false,
  ),
  createData(
    42,
    'ИП "Строй***"',
    'Заказ кабеля - 100 метров',
    getRandomDate(),
    true,
  ),
  createData(
    43,
    'ООО "Строй***"',
    'Покупка дверей - 10 штук',
    getRandomDate(),
    true,
  ),
  createData(
    44,
    'Аг***ойПанель, ООО, производственная компания',
    'Заказ оконных рам - 20 штук',
    getRandomDate(),
    false,
  ),
  createData(
    45,
    'ИП "Строй***"',
    'Покупка изоляции - 50 рулонов',
    getRandomDate(),
    true,
  ),
  createData(
    45,
    'ООО "Строй***"',
    'Заказ керамического блока - 500 штук',
    getRandomDate(),
    true,
  ),
  createData(
    46,
    'ООО "Лам***икс"',
    'Покупка гвоздей - 100 кг',
    getRandomDate(),
    false,
  ),
  createData(
    47,
    'ООО "Строй***"',
    'Заказ металлочерепицы - 50 листов',
    getRandomDate(),
    true,
  ),
  createData(
    48,
    'ООО "Инг***ом"',
    'Покупка герметика - 10 штук',
    getRandomDate(),
    false,
  ),
  createData(
    49,
    'ООО "Строй***"',
    'Покупка дренажной трубы - 20 метров',
    getRandomDate(),
    true,
  ),
  createData(
    50,
    'ООО "Строй***"',
    'Заказ строительного мусора - 10 кубометров',
    getRandomDate(),
    true,
  ),
  createData(
    51,
    'Аг***ойПанель, ООО, производственная компания',
    'Покупка садового камня - 100 килограмм',
    getRandomDate(),
    false,
  ),
  createData(
    52,
    'ООО "Строй***"',
    'Заказ профиля для гипсокартона - 50 штук',
    getRandomDate(),
    true,
  ),
  createData(
    53,
    'ООО "Строй***"',
    'Покупка теплоизоляции - 20 рулонов',
    getRandomDate(),
    true,
  ),
  createData(
    54,
    'ООО Группа Компаний "Ар***а"',
    'Заказ пеноблоков - 100 штук',
    getRandomDate(),
    false,
  ),
];

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#717171',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:hover': {
    backgroundColor: '#e5eefe !important',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function StickyHeadTable() {
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (tableRef.current) {
        const newScrollTop = tableRef.current?.scrollTop + 60;
        tableRef.current.scrollTo({
          top: newScrollTop,
          behavior: 'smooth',
        });
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <Paper elevation={0.1} sx={{p: 2, width: '100%', overflow: 'hidden'}}>
      <Typography
        variant="h4"
        color="textPrimary"
        style={{
          display: 'inline',
          fontWeight: 'bold',
          marginLeft: '12px',
        }}
      >
        Мы обработали уже{' '}
        <Typography
          variant="h4"
          color="primary"
          style={{
            display: 'inline',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          <b>1000 заявок</b>
        </Typography>
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{marginTop: '10px', marginLeft: '14px', marginBottom: '10px'}}
      >
        Убедитесь в качестве наших услуг
      </Typography>
      <div>
        <TableContainer
          ref={tableRef}
          style={{maxHeight: '380px', overflowY: 'hidden'}}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{minWidth: column.minWidth}}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'status' ? ( // Check if column.id is 'status'
                            <Chip
                              color="primary"
                              label={value ? 'Доставлено' : 'Не доставлено'}
                              variant={value ? 'filled' : 'outlined'}
                            /> // Display value inside a Chip component
                          ) : (
                            value // Display value directly for other columns
                          )}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{marginTop: '20px'}}
        >
          <BlueText />
        </Typography>
      </div>
    </Paper>
  );
}
