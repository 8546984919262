import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Stack,
  Grid,
} from '@mui/material';
import React, {useState} from 'react';
import s from './main.module.scss';
import Ajax from '../../../api/ajax';
import {SearchCategoriesResponseI} from '../../../api/messageTypes';
import CompanyCategoryRenderOption from '../../../components/suggestionsInput/CompanyCategoryRenderOption';
import {APIurl, ajaxDebug} from '../../../utils/globals';
import {fastOrderValidation} from '../../forms/fastOrderValidation';
import SuggestionsInput, {
  SuggestionsInputStateI,
} from '../../../components/suggestionsInput/suggestionsInputComponent';
import find from '../../../static/homepage/icons/find.svg';
import {useNavigate} from 'react-router';
import ModalHowDItWork from '../../../components/Modals/HowDoesItWork';
import {dataMok, typeStory, dataMokStory, dataMokCompressed} from './constants';
import arrowStory from '../../../static/imgs/Arrow-store.svg';
import {changeCategory} from '../../../redux/reducers/categoriesReducer';
import {useAppDispatch} from '../../../redux/hooks';
import CategoriesData from '../../../components/CategoryCard/categories_data';

const Main: React.FC = () => {
  const [isSearchValid, setIsSearchValid] = useState(false);
  const [searchCategory, setSearchCategory] = useState('');
  const [searchCategorySuggestions, setSearchCategorySuggestions] = useState<
    SuggestionsInputStateI<SearchCategoriesResponseI>
  >({
    records: [],
    currentRecordIndex: -1,
  });

  const [isAuthed, setIsAuthed] = useState(false);
  React.useEffect(() => {
    fetch(`${APIurl}/user/me`, {credentials: 'include'}).then((res) =>
      res.status === 401 ? setIsAuthed(false) : setIsAuthed(true),
    );
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matches = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <Box component="main" className={s.main}>
        <Box component="form" className={s.main__form_block}>
          <Box className={s.main__form_block__input}>
            <img src={find} alt="" />
            <SuggestionsInput<SearchCategoriesResponseI>
              // eslint-disable-next-line
              createRequestPromise={async (
                query: string,
              ): Promise<SearchCategoriesResponseI[]> => {
                if (!query) {
                  if (ajaxDebug)
                    // eslint-disable-next-line
                    console.log(
                      '[FastOrder Form => SuggestionsInput] не отправляю пустое поле',
                    );
                  return (async () => [])();
                }

                return Ajax.post(
                  {
                    url: `/search/categories?skip=0&limit=6`,
                    body: {name: query},
                  },
                  true,
                )
                  .then(({status, response}) => {
                    return (
                      (status === Ajax.STATUS.ok &&
                        (response.data as SearchCategoriesResponseI[])) ||
                      []
                    );
                  })
                  .catch((err) => {
                    console.warn(err);
                    return [];
                  });
              }}
              substituteOnClick={(suggestion: {name: string}) =>
                suggestion.name
              }
              validatableInputProps={{
                conditions: fastOrderValidation.productCategory.conditions,
                required: true,
                trimmed: false,
                labelSign: 'Введите название товара, артикул или продавца',
                id: 'item-category',
                tooltipWidth: '200px',
                validState: [isSearchValid, setIsSearchValid],
              }}
              manageSuggestionsState={[
                searchCategorySuggestions,
                setSearchCategorySuggestions,
              ]}
              manageValueState={[searchCategory, setSearchCategory]}
              RenderOption={CompanyCategoryRenderOption}
            />
          </Box>
          <Button
            type="submit"
            className={s.main__form_block__button}
            style={{backgroundColor: '#375ba1', color: 'white'}}
          >
            Найти
          </Button>
        </Box>

        <Box
          component="main"
          style={{
            paddingLeft: '62px',
            paddingRight: '62px',
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <Stack display="flex" flexDirection={'column'}>
              <Typography component={'h6'} fontSize="36px" fontWeight={600}>
                Популярные категории
              </Typography>
              <Grid
                container
                mt="30px"
                rowSpacing={2}
                sx={{
                  width: '825px',
                  height: '367px',
                  gap: '12px',
                }}
              >
                {dataMokCompressed.map((item) => {
                  const properCategory =
                    CategoriesData.find((c) =>
                      item.link.includes(c.translatedName),
                    )?.translatedName || '';
                  return (
                    <Box
                      key={item.id}
                      component={'a'}
                      // href={item.link}
                      onClick={() => {
                        navigate(`/categories/${properCategory}`);
                        dispatch(changeCategory(properCategory));
                        localStorage.setItem('CategoryName', properCategory);
                      }}
                      sx={{
                        width: '267px',
                        height: '116px',
                        cursor: 'pointer',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderRadius: '8px',
                        border: '1px solid #E6EAF7',
                        padding: '0 5px',
                      }}
                    >
                      <Typography
                        fontSize="18px"
                        fontWeight={'500'}
                        mt="20px"
                        ml="15px"
                      >
                        {item.title}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          flexDirection: 'column',
                        }}
                      >
                        <img
                          src={item.image}
                          alt="пеноблок"
                          style={{width: '116px', height: '96px'}}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Grid>
            </Stack>
            <Stack
              display="flex"
              flexDirection={'column'}
              sx={{
                width: '405px',
                backgroundColor: '#6A8CFF',
                borderRadius: '8px',
              }}
            >
              <Box
                sx={{
                  margin: '30px 0 30px 30px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  backgroundColor: '#F9FAFF',
                  borderTopLeftRadius: '12px',
                  borderBottomLeftRadius: '12px',
                }}
              >
                <Box
                  sx={{
                    padding: '26px 19px',
                  }}
                >
                  <Typography fontSize={'28px'} fontWeight="500">
                    Разместить объявление
                  </Typography>
                  <Typography
                    fontSize={'16px'}
                    fontWeight="400"
                    sx={{color: '#7A86B2'}}
                  >
                    Привлекайте новых клиентов ежедневно на нашей платформе
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  margin: '20px 30px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  gap: '5px',
                }}
              >
                <Typography fontSize="28px" fontWeight={600} color={'#FFFFFF'}>
                  Покупайте и продавайте быстро
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  color={'#FFFFFF'}
                  mb={'10px'}
                >
                  Ускорьте процесс закупки и находите новых клиентов благодаря
                  битуби
                </Typography>
                <Button
                  href={isAuthed ? '/product/add' : '/register'}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    width: '345px',
                    height: '45px',
                    padding: '12px 40px',
                    borderRadius: '8px',
                  }}
                >
                  <Typography
                    fontSize={'16px'}
                    color="#272121"
                    textAlign={'center'}
                  >
                    {isAuthed ? 'Добавить товары' : 'Найти клиентов'}
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>

        {/* <ModalHowDItWork
        open={open}
        handleClose={handleClose}
        handleIconClose={handleIconClose}
        isMobile={matches ? true : false}
      /> */}
      </Box>
      <Box
        sx={{
          margin: '65px auto',
          width: '1240px',
          height: '165px',
          display: 'flex',
          flexDirection: 'row',
          gap: '55px',
        }}
      >
        {dataMokStory.map((item) => {
          return (
            <Stack
              key={item.id}
              display={'flex'}
              flexDirection={'column'}
              gap="12px"
            >
              <Box component={'a'} href={item.link} target="_blank">
                <img
                  src={item.image}
                  alt="картинка сторис"
                  style={{
                    width: '130px',
                    height: '130px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Typography
                fontSize={'18px'}
                fontWeight={'500'}
                sx={{
                  width: '120px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.title}
              </Typography>
            </Stack>
          );
        })}
        <Stack display={'flex'} flexDirection={'column'} gap="12px">
          <Box>
            <img
              src={arrowStory}
              alt="картинка сторис"
              style={{width: '130px', height: '130px'}}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Main;
