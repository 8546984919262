import React, {ChangeEvent, FC, useState} from 'react';
import RootPage from './rootPage';

import TrashIcon from '../images/trash.svg';
import EstimateIcon from '../images/estimate.svg';

import CartItem from '../components/cartItem/cartItem';

import './pages.scss';

const CartPage: FC = () => {
  const [areItemsChecked, setAreItemsChecked] = useState(false);

  const items = [
    {
      id: '1',
      name: 'Кирпич рядовой ЛСР (РКЗ) полнотелый М250 250х120х65 мм 1NF',
      price: 1900,
      companyName: 'Название компании',
    },
    {
      id: '2',
      name: 'Кирпич рядовой ЛСР (РКЗ) полнотелый М250 250х120х65 мм 1NF',
      price: 1900,
      companyName: 'Название компании',
    },
    {
      id: '3',
      name: 'Кирпич рядовой ЛСР (РКЗ) полнотелый М250 250х120х65 мм 1NF',
      price: 1900,
      companyName: 'Название компании',
    },
  ];

  const calcTotalPrice = () => {
    let total = 0;
    items.forEach((item) => total += item.price);
    return total;
  };

  return (
    <RootPage nameOfActive='Cart'>
      <div className='row theme-blue l-center white-text'>
        <h5 className='mv-20'>
          Занимайтесь стройкой, а лучших поставщиков мы найдём за вас!
        </h5>
      </div>
      <div className="container">
        <div className="cart__body">
          <div className="cart__products">
            <header className="cart__header">
              <ul className="cart__header-list">
                <li className="cart__header-item">
                  <label htmlFor="select-all-checkbox" className="cart__checkbox-label">
                    <input
                      onChange={(e: ChangeEvent) => setAreItemsChecked((e.target as HTMLInputElement)?.checked)}
                      type="checkbox"
                      id="select-all-checkbox"
                      className='cart__checkbox cart__header-icon'
                    />
                    Выбрать все
                  </label>
                </li>
                <li className="cart__header-item">
                  <img src={TrashIcon} alt="delete" className='cart__header-icon'/>
                  Удалить
                </li>
                <li className="cart__header-item">
                  <img src={EstimateIcon} alt="delete" className='cart__header-icon'/>
                  Создать смету
                </li>
              </ul>
            </header>
            {
              items.map(({id, name, companyName, price}, i) => {
                return <CartItem
                  id={id}
                  key={''+i}
                  name={name}
                  companyName={companyName}
                  price={price}
                  checkedAll={areItemsChecked}
                />;
              })
            }

          </div>
          <aside className="cart__sidebar">
            <h1 className="cart__sidebar-title">Оформление заказа</h1>
            <form action="post" className="cart__form">
              <div>
                <label htmlFor="inn">ИНН</label>
                <input
                  type="text"
                  className='cart__form-input'
                  name='inn'
                  id='inn'
                  placeholder='Введите ваш ИНН'
                />
              </div>

              <div>
                <label htmlFor="delivery-date">Дата доставки</label>
                <input
                  type="date"
                  className='cart__form-input'
                  name='delivery-date'
                  id='delivery-date'
                />
              </div>

              <div>
                <label htmlFor="delivery-address">Адрес доставки</label>
                <input
                  type="text"
                  className='cart__form-input'
                  name='delivery-address'
                  id='delivery-address'
                  placeholder='Введите полный адрес'
                />
              </div>

              <div className="cart__data">
                <div className="cart__data-top">
                  <span className='cart__data-title'>Ваша корзина</span>
                  <div className="cart__data-order">{items.length} товара | 1000 кг</div>
                </div>
                <div className="cart__data-bottom">
                  <span>Товары ({items.length})</span>
                  <span>{calcTotalPrice()} Р</span>
                </div>
              </div>
              <div className="cart__total">
                Итого
                <span className="cart__total-price">
                  {calcTotalPrice()} Р
                </span>
              </div>
              <input type="submit" value='Оформить' className='btn theme-blue cart__sidebar-btn'/>
            </form>
          </aside>
        </div>
      </div>
    </RootPage>
  );
};

export default CartPage;
