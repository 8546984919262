/* eslint-disable */
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Box, Stack, Typography} from '@mui/material';
import style from './chatslist.module.scss';
import {ChatData} from '../types';
import {useAppDispatch} from '../../../redux/hooks';
import {APIurl} from '../../../utils/globals';
import {changeStatusListAction} from '../../../redux/reducers/chatListReducer';

type ChatListProps = {
  lastMsgList: ChatData[];
};

function ChatList({lastMsgList}: ChatListProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const sortedChatList = React.useMemo(() => {
    if (lastMsgList) {
      const sortArray = [...lastMsgList];
      return sortArray.sort((a, b) => {
        return new Date(b.last_msg.time.split('+')[0]) >
          new Date(a.last_msg.time.split('+')[0])
          ? 1
          : -1;
      });
    }
  }, [lastMsgList]) as ChatData[];

  function onDelete(id: number) {
    const request = fetch(`${APIurl}/chat/delete`, {
      method: 'DELETE',
      credentials: 'include',
      body: JSON.stringify({
        chat_id: id,
      }),
    }).then((res) => {
      if (res.status === 200) {
        navigate('/chat');
        dispatch(changeStatusListAction(true));
      }
    });
  }

  return (
    <Box sx={{height: '100%'}}>
      <Box
        className={style.chatslist}
        sx={{overflowY: sortedChatList?.length > 8 ? 'scroll' : null}}
      >
        {sortedChatList &&
          sortedChatList.map((item: any, index) => {
            const timeDate = item.last_msg.time.split('m')[0];
            const timeString = new Date(timeDate).toLocaleTimeString('ru-US', {
              hour: 'numeric',
              minute: 'numeric',
            });
            const isBlur = item.chat.blured ? 'blur(5px)' : 'blur(0)';
            return (
              <Box key={index} className={style.chatslist__container}>
                <Link
                  to={item.chat.blured ? '#' : `/chat/${item.chat.id}`}
                  style={{paddingBottom: '20px'}}
                >
                  <Stack
                    height={'30px'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexDirection={'row'}
                  >
                    <Box className={style.chatslist__title}>
                      <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: '500',
                            lineHheight: '19px',
                            whiteSpace: 'nowrap',
                            width: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.company.name}.
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '13px',
                            fontWeight: '400',
                            lineHheight: '19px',
                            whiteSpace: 'nowrap',
                            width: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: 'grey',
                            filter: isBlur,
                          }}
                        >
                          {item.company.legal_name}.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{filter: isBlur}}
                      className={style.chatslist__delete}
                      onClick={() => (isBlur ? null : onDelete(item.chat.id))}
                    />
                  </Stack>
                  <Stack
                    display="flex"
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    sx={{filter: isBlur}}
                  >
                    <Typography className={style.chatslist__message}>
                      {item.last_msg.text}
                    </Typography>
                    <Typography>{timeString}</Typography>
                  </Stack>

                  <Box
                    sx={{
                      borderRadius: '100px',
                      maxWidth: '102px',
                      height: '27px',
                      background: '#61B43A',
                      display: 'flex',
                      justifyContent: 'center',
                      filter: isBlur,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        lineHeight: '15px',
                      }}
                    >
                      {item.chat.status}
                    </Typography>
                  </Box>
                </Link>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}

export default ChatList;
