/* eslint-disable*/
import React from 'react';
import {InputT} from './InputT';
import {CategoryDataT} from './types';
import axios from 'axios';
import {APIurl} from '../../utils/globals';

interface DropdownT extends InputT {
  categoryIdState: [
    number | undefined,
    React.Dispatch<React.SetStateAction<number | undefined>>,
  ];
}

const Dropdown: React.FC<DropdownT> = ({
  label,
  categoryIdState,
  placeholder,
}) => {
  const [query, setQuery] = React.useState('');
  const [suggestions, setSuggestions] = React.useState<CategoryDataT>([]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);

    try {
      const response = await axios.post(
        `${APIurl}/search/categories?skip=0&limit=10`,
        {name: value},
      );
      setSuggestions(response.data.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleSelection = (selectedItem: string, selectedId?: number) => {
    setQuery(selectedItem);
    categoryIdState[1](selectedId);
    setSuggestions([]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const selectedItem = event.currentTarget.innerText;
      handleSelection(selectedItem);
    }
  };

  const renderSuggestions = () => (
    <ul className="absolute z-10 w-full bg-white shadow-lg">
      {suggestions.map((item) => (
        <li
          key={item.id}
          role="option"
          tabIndex={0}
          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
          onClick={() => handleSelection(item.name, item.id)}
          onKeyDown={handleKeyDown}
        >
          {item.name}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="relative">
      {label?.length && (
        <p
          style={{
            color: '#707895',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '19px',
          }}
        >
          {label}
        </p>
      )}
      <input
        type="text"
        placeholder={placeholder}
        className={'!mt-2 focus:outline-none focus:ring focus:border-blue-400'}
        style={{
          borderRadius: '8px',
          border: '1px solid rgba(29, 54, 83, 0.17',
          background: '#FAFCFF',
          padding: '15px 16px',
          maxHeight: '30px',
        }}
        value={query}
        onChange={handleChange}
      />
      {query && suggestions && renderSuggestions()}
    </div>
  );
};

export default Dropdown;
