/* eslint-disable */
import React from 'react';
import {InputT} from './InputT';
import {cn} from '../../utils/cn';

interface CheckboxGroupT extends InputT {
  options: string[];
  selectedValues: string[];
  onChange: (arg: any) => void;
}

const CheckboxGroup: React.FC<CheckboxGroupT> = ({
  options,
  selectedValues,
  onChange,
  className,
  label,
}) => {
  const handleCheckboxChange = (value: string, checked: boolean) => {
    const updatedSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((val) => val !== value);
    onChange(updatedSelectedValues);
  };

  return (
    <div className={cn('flex', className)}>
      <p
        style={{
          color: 'var(--text-secondary, #707895)',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '500',
        }}
        className="mb-[20px]"
      >
        {label}
      </p>
      {options.map((option) => (
        <label key={option} className="flex gap-2 mb-6">
          <input
            type="checkbox"
            value={option}
            checked={selectedValues.includes(option)}
            onChange={(e) => handleCheckboxChange(option, e.target.checked)}
          />
          <p style={{color: '#272121'}} className="font-medium text-[14px]">
            {option}
          </p>
        </label>
      ))}
    </div>
  );
};

export default CheckboxGroup;
