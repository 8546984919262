import React from 'react';
import {createRoot} from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';

import AppRouter from './routing/appRouter';

import ModalComponent from './components/modal/modalComponent';

import store from './redux/store';
// import './index.scss';
import './static/scss/index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ModalComponent title='' isOpened={false}><div></div></ModalComponent> */}
      <AppRouter />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
