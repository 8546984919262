import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Navigate, useNavigate, useParams} from 'react-router';
import {ChatData} from '../types';
import ButtonOptions from '../ButtonOptions/ButtonOptions';
import {mokChatsAll} from '../utils/constants';

interface TitleChatProps {
  dataChat: ChatData[];
}

function TitleChat({dataChat}: TitleChatProps) {
  const naviagate = useNavigate();
  const param = useParams();
  const currentId = param.id;
  const currentChat =
    dataChat && dataChat.find((item) => `${item.chat.id}` === currentId);

  function goCompanyProducts() {
    naviagate(`../../company/${currentChat?.company.id}`);
  }

  return (
    <Box
      sx={{
        height: '75px',
        borderBottom: '1px solid #1D36532B',
        padding: '20px 22px',
        display: 'flex',
        gap: '28px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{
          flexDirection: 'columng',
          gap: '8px',
          alignItems: 'center',
        }}
        onClick={() => goCompanyProducts()}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Чат по объявлению: {currentChat?.chat.name}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            color: '#707895',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {currentChat?.company.legal_name}
        </Typography>
      </Stack>
      <Box>
        <ButtonOptions />
      </Box>
      {/* <Box className={style.offer__container}>

        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            color: 'white',
            lineHeight: '14px',
            whiteSpace: 'nowrap',
          }}
        >
          Добавить статус &#11167;
        </Typography>
      </Box> */}
    </Box>
  );
}
export default TitleChat;
