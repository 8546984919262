import React, {ChangeEvent, MouseEvent, FocusEvent, ReactNode, ElementType} from 'react';
import shallowEqual from 'shallowequal';
import {debounce} from 'debounce';
// import {nanoid} from 'nanoid';
import {CommonProps, DaDataSuggestion, DadataRequestPromise, BaseState, BaseProps} from './types';
import {DefaultHttpCache, HttpCache} from './http-cache';
import { nanoid } from '@reduxjs/toolkit';

//   method: string,
//   url: string,
//   body: Record<string, string>,
//   onReceiveData: (response: Record<string, string>) => void,
//   cache?: HttpCache,
//   headers?: Record<string, string>,
// ): void => {
//   if (xhr) {
//     xhr.abort();
//   }

//   let cacheKey: string;
//   if (cache) {
//     cacheKey = cache.serializeCacheKey({
//       headers: headers,
//       body,
//       url,
//       method,
//     });
//     const cachedData = cache.get(cacheKey);
//     if (cachedData) {
//       onReceiveData(cachedData);
//       return;
//     }
//   }
//   xhr = new XMLHttpRequest();
//   xhr.open(method, url);
//   if (headers) {
//     Object.entries(headers).forEach(([header, headerValue]) => {
//       xhr.setRequestHeader(header, headerValue);
//     });
//   }
//   xhr.send(JSON.stringify(body));

//   xhr.onreadystatechange = () => {
//     if (!xhr || xhr.readyState !== 4) {
//       return;
//     }

//     if (xhr.status === 200) {
//       const payload = JSON.parse(xhr.response)?.suggestions;
//       if (payload) {
//         cache?.set(cacheKey, payload);
//         onReceiveData(payload);
//       }
//     }
//   };
// };

export abstract class BaseSuggestions<SuggestionType, OwnProps> extends React.PureComponent<
  BaseProps<SuggestionType> & OwnProps,
  BaseState<SuggestionType>
> {
  /**
   * URL для загрузки подсказок, переопределяется в конкретном компоненте
   */
  protected loadSuggestionsUrl = '';

  protected dontPerformBlurHandler = false;

  protected _uid?: string;

  protected didMount: boolean;

  private createRequestPromise (value: string, cache: HttpCache | null): DadataRequestPromise<SuggestionType> {
    const url: string = this.getSuggestionsUrl();
      const { token = '' } = this.props;

      if (!token) {
        console.error(`
A Dadata token is required.
See docs for more information.
https://github.com/vitalybaev/react-dadata
        `);
      }

      const requestParams = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.getLoadSuggestionsData()) as BodyInit,
      } as RequestInit;

      let cacheKey: string;
      if (cache) {
        cacheKey = cache.serializeCacheKey({url, requestParams});
        const cachedData = cache.get(cacheKey) as unknown as DaDataSuggestion<SuggestionType>[];
        if (cachedData) {
          return (async () => cachedData)();
        }
      }
      // console.log('FETCH', {url, requestParams})
      return fetch(url, requestParams)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        return {suggestions: []};
      }).then((json) => {
        cache?.set(cacheKey, json.suggestions);
        return json.suggestions;
      }) as Promise<DaDataSuggestion<SuggestionType>[]>;
    };

  /**
   * HTML-input
   */
  private textInput?: HTMLInputElement;


  constructor(props: BaseProps<SuggestionType> & OwnProps) {
    super(props);

    this.didMount = false;

    const { defaultQuery, value, delay } = this.props;
    const valueQuery = value ? value.value : undefined;

    this.setupDebounce(delay);

    this.state = {
      query: (defaultQuery as string | undefined) || valueQuery || '',
      inputQuery: (defaultQuery as string | undefined) || valueQuery || '',
      isFocused: false,
      displaySuggestions: true,
      suggestions: [],
      suggestionIndex: -1,
    };
  }

  componentDidMount() {
    this.didMount = true;
  }

  componentDidUpdate(prevProps: Readonly<BaseProps<SuggestionType> & OwnProps>): void {
    const { value, delay } = this.props;
    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    const { query, inputQuery } = state;

    if (this.props.manageState) {
      const prevState = prevProps.manageState && prevProps.manageState[0];
      if (state.query !== prevState?.query) {
        // console.log('state.query !== prevState.query => fetch');
        this.fetchSuggestions();
        return;
      }
    }

    if (!shallowEqual(prevProps.value, value)) {
      const newQuery = value ? value.value : '';
      if (query !== newQuery || inputQuery !== newQuery) {
        setState({ ...state, query: newQuery, inputQuery: newQuery });
      }
    }

    if (delay !== prevProps.delay) {
      this.setupDebounce(delay);
    }
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  get uid(): string {
    if (this.props.uid) {
      return this.props.uid;
    }
    if (!this._uid) {
      this._uid = nanoid();
    }
    return this._uid!;
  }

  get httpCache(): HttpCache | null {
    const { httpCache: cacheProp, httpCacheTtl: ttl } = this.props;
    if (!cacheProp) {
      return null;
    }
    if (cacheProp instanceof HttpCache) {
      return cacheProp;
    }
    const cache = DefaultHttpCache.shared;
    if (typeof ttl === 'number') {
      cache.ttl = ttl;
    }
    return cache;
  }

  protected getSuggestionsUrl = (): string => {
    const { url } = this.props;

    return url || this.loadSuggestionsUrl;
  };

  protected setupDebounce = (delay: number | undefined): void => {
    if (typeof delay === 'number' && delay > 0) {
      this.fetchSuggestions = debounce(this.performFetchSuggestions, delay);
    } else {
      this.fetchSuggestions = this.performFetchSuggestions;
    }
  };

  /**
   * Функция, которая вернет данные для отправки для получения подсказок
   */
  protected abstract getLoadSuggestionsData(): Record<string, unknown>;

  protected fetchSuggestions = (): void => {
    //
  };

  private handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    // console.log('handleInputFocus')
    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    const { suggestions } = state;

   setState({...state, isFocused: true });

    if (suggestions.length === 0) {
      this.fetchSuggestions();
    }

    const { inputProps } = this.props;
    if (inputProps && inputProps.onFocus) {
      inputProps.onFocus(event);
    }
  };

  private handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    // console.log('dadata handleBlur')

    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    const { suggestions, suggestionIndex } = state;
    const { selectOnBlur, inputProps } = this.props;

    setState({ ...state, isFocused: false });
    if (suggestions.length === 0) {
      this.fetchSuggestions();
    }

    if (selectOnBlur && !this.dontPerformBlurHandler) {
      if (suggestions.length > 0) {
        const suggestionIndexToSelect =
          suggestionIndex >= 0 && suggestionIndex < suggestions.length ? suggestionIndex : 0;
        this.selectSuggestion(suggestionIndexToSelect, true);
      }
    }

    this.dontPerformBlurHandler = false;

    if (inputProps && inputProps.onBlur) {
      inputProps.onBlur(event);
    }
  };

  private handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // console.log('dadata handleInputChange')
    const { value } = event.target;
    const { inputProps } = this.props;
    if (this.didMount) {
      const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
      if (this.props.manageState) {
        setState({ ...state, query: value, inputQuery: value, displaySuggestions: !!value });
      } else {
        setState({ ...state, query: value, inputQuery: value, displaySuggestions: !!value }, () => {
          this.fetchSuggestions();
        });
      }
    }

    if (inputProps && inputProps.onChange) {
      inputProps.onChange(event);
    }
  };

  private handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    this.handleKeyboard(event);

    const { inputProps } = this.props;
    if (inputProps && inputProps.onKeyDown) {
      inputProps.onKeyDown(event);
    }
  };

  private handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    this.handleKeyboard(event);

    const { inputProps } = this.props;
    if (inputProps && inputProps.onKeyPress) {
      inputProps.onKeyPress(event);
    }
  };

  private handleKeyboard = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    const { suggestions, suggestionIndex, inputQuery } = state;
    if (event.which === 40) {
      // Arrow down
      event.preventDefault();
      if (suggestionIndex < suggestions.length - 1) {
        const newSuggestionIndex = suggestionIndex + 1;
        const newInputQuery = suggestions[newSuggestionIndex].value;
        if (this.didMount) {
          setState({ ...state, suggestionIndex: newSuggestionIndex, query: newInputQuery });
        }
      }
    } else if (event.which === 38) {
      // Arrow up
      event.preventDefault();
      if (suggestionIndex >= 0) {
        const newSuggestionIndex = suggestionIndex - 1;
        const newInputQuery = newSuggestionIndex === -1 ? inputQuery : suggestions[newSuggestionIndex].value;
        if (this.didMount) {
          setState({ ...state, suggestionIndex: newSuggestionIndex, query: newInputQuery });
        }
      }
    } else if (event.which === 13) {
      // Enter
      event.preventDefault();
      if (suggestionIndex >= 0) {
        this.selectSuggestion(suggestionIndex);
      }
    }
  };

  private performFetchSuggestions = () => {
    const { minChars } = this.props;
    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    const { query } = state;

    // Проверяем на минимальное количество символов для отправки
    if (typeof minChars === 'number' && minChars > 0 && query.length < minChars) {
      setState({ ...state, suggestions: [], suggestionIndex: -1 });
      return;
    }
    const createRequestPromise = this.props.createRequestPromise || this.createRequestPromise.bind(this);
    createRequestPromise!(query, this.httpCache).then((suggestions) => {
      if (this.didMount) {
        const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
        setState({ ...state, suggestions, suggestionIndex: -1 });
      }
    });
  };

  private onSuggestionClick = (index: number, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.selectSuggestion(index);
  };

  private selectSuggestion = (index: number, isSilent = false) => {
    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    const { suggestions, query } = state;
    const { selectOnBlur, onOptionSelect, substituteOnClick } = this.props;

    if (suggestions.length >= index - 1) {
      const suggestion = suggestions[index];
      if (selectOnBlur) {
        this.dontPerformBlurHandler = true;
      }

      const newValue = typeof substituteOnClick === 'function' ? substituteOnClick(suggestion) : suggestion.value;
      setState({ ...state, query: newValue, inputQuery: newValue, displaySuggestions: false });
      // this.setState({ query: suggestion.value, inputQuery: suggestion.value, displaySuggestions: false }, () => {
      //   if (!isSilent) {
      //     this.fetchSuggestions();
      //     setTimeout(() => this.setCursorToEnd(this.textInput));
      //   }
      // });

      if (onOptionSelect) {
        onOptionSelect(suggestion);
      }
    }
  };

  private setCursorToEnd = (element: HTMLInputElement | undefined) => {
    if (element) {
      const valueLength = element.value.length;
      if (element.selectionStart || element.selectionStart === 0) {
        // eslint-disable-next-line no-param-reassign
        element.selectionStart = valueLength;
        // eslint-disable-next-line no-param-reassign
        element.selectionEnd = valueLength;
        element.focus();
      }
    }
  };

  protected getHighlightWords = (): string[] => {
    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    const { inputQuery } = state;
    const wordsToPass = ['г', 'респ', 'ул', 'р-н', 'село', 'деревня', 'поселок', 'пр-д', 'пл', 'к', 'кв', 'обл', 'д'];
    let words = inputQuery.replace(',', '').split(' ');
    words = words.filter((word) => {
      return wordsToPass.indexOf(word) < 0;
    });
    return words;
  };

  /**
   * Функция, которая вернет уникальный key для списка React
   * @param suggestion
   */
  protected getSuggestionKey = (suggestion: DaDataSuggestion<SuggestionType>): string => suggestion.value;

  public focus = (): void => {
    if (this.textInput) {
      this.textInput.focus();
    }
  };

  public setInputValue = (value?: string): void => {
    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    setState({ ...state, query: value || '', inputQuery: value || '' });
  };

  protected abstract renderOption(suggestion: DaDataSuggestion<SuggestionType>): ReactNode;

  public render(): ReactNode {
    const {
      inputProps,
      hintText,
      containerClassName,
      hintClassName,
      suggestionsClassName,
      suggestionClassName,
      currentSuggestionClassName,
      customInput,
      children,
    } = this.props;
    const [state, setState] = this.props.manageState || [this.state, this.setState.bind(this)];
    const { query, isFocused, suggestions, suggestionIndex, displaySuggestions } = state;
    // console.log('dadata render')

    const Component = typeof customInput !== 'undefined' ? (customInput as ElementType) : 'input';

    const optionsExpanded = isFocused && suggestions && displaySuggestions && suggestions.length > 0;
    return (
      <div
        role='combobox'
        aria-expanded={optionsExpanded ? 'true' : 'false'}
        aria-owns={this.uid}
        aria-controls={this.uid}
        aria-haspopup='listbox'
        className={containerClassName || 'react-dadata react-dadata__container'}
      >
        <div>
          <Component
            autoComplete='off'
            className='react-dadata__input'
            {...inputProps}
            value={query}
            // ref={(input: HTMLInputElement) => {
            //   this.textInput = input;
            // }}
            onChange={this.handleInputChange.bind(this)}
            onKeyPress={this.handleInputKeyPress.bind(this)}
            onKeyDown={this.handleInputKeyDown.bind(this)}
            onFocus={this.handleInputFocus.bind(this)}
            onBlur={this.handleInputBlur.bind(this)}
          />
        </div>
        {optionsExpanded && (
          <ul
            id={this.uid}
            aria-expanded
            role='listbox'
            style={{boxShadow: '0px 5px 10px 2px #000'}}
            className={suggestionsClassName || 'react-dadata__suggestions'}
          >
            {typeof hintText !== 'undefined' && (
              <div className={hintClassName || 'react-dadata__suggestion-note'}>{hintText}</div>
            )}
            {suggestions.map((suggestion, index) => {
              let suggestionClass = suggestionClassName || 'react-dadata__suggestion';
              if (index === suggestionIndex) {
                suggestionClass += ` ${currentSuggestionClassName || 'react-dadata__suggestion--current'}`;
              }
              return (
                <button
                  role='option'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    backgroundColor: 'white',
                    color: '#558AF0',
                    padding: '8px 4px',
                    width: '100%'
                  }}
                  // TODO: вернуть как было
                  // aria-selected={index === suggestionIndex ? 'true' : 'false'}
                  aria-selected='true'
                  key={this.getSuggestionKey(suggestion)}
                  onMouseDown={this.onSuggestionClick.bind(this, index)}
                  className={suggestionClass}
                >
                  {this.renderOption(suggestion)}
                </button>
              );
            })}
          </ul>
        )}
        {children}
      </div>
    );
  }
}
