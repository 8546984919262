import parners1 from '../../../static/aboutPage/Partner1.png';
import parners2 from '../../../static/aboutPage/Partner2.png';
import parners3 from '../../../static/aboutPage/Partner3.png';
import parners4 from '../../../static/aboutPage/Partner4.png';
import parners5 from '../../../static/aboutPage/Partner5.png';
import parners6 from '../../../static/aboutPage/Partner6.png';

export const dataLogoMok = [
  {
    id: 1,
    title: 'Logo',
    pic: parners1,
  },
  {
    id: 2,
    title: 'Logo',
    pic: parners2,
  },
  {
    id: 3,
    title: 'Logo',
    pic: parners3,
  },
  {
    id: 4,
    title: 'Logo',
    pic: parners4,
  },
  {
    id: 5,
    title: 'Logo',
    pic: parners5,
  },
  {
    id: 6,
    title: 'Logo',
    pic: parners6,
  },

];
