import iconCard from '../../../static/aboutPage/iconCard.png';
import iconPeople from '../../../static/aboutPage/iconPeople.png';
import iconHandSnake from '../../../static/aboutPage/iconHandSnake.png';

export const mockData = [
  {
    id: 1,
    icon: iconCard,
    title: '1 000 000',
    subtitle: 'Объявлений на площадке',
  },
  {
    id: 2,
    icon: iconCard,
    title: '1 000 000',
    subtitle: 'Объявлений на площадке',
  },
  {
    id: 3,
    icon: iconPeople,
    title: '100',
    subtitle: 'Поставщиков',
  },
  {
    id: 4,
    icon: iconHandSnake,
    title: '10 000',
    subtitle: 'Счастливых клиентов',
  },
];
