import React, {FC, FormEventHandler, MouseEventHandler, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useMaterialize from '../../hooks/useMaterialize';

import SuggestionsInput from '../../components/suggestionsInput/suggestionsInputComponent';
import ValidatableInput from '../../components/validatableInput/validatableInputComponent';
import CompanyCategoryRenderOption from '../../components/suggestionsInput/CompanyCategoryRenderOption';

import Ajax from '../../api/ajax';
import regexpValidation from '../../utils/regexp';

import type {SearchCategoriesResponseI} from '../../api/messageTypes';
import type {SuggestionsInputStateI} from '../../components/suggestionsInput/suggestionsInputComponent';
import {ajaxDebug} from '../../utils/globals';
import {fastOrderValidation} from './fastOrderValidation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FastOrderFormPropsI {

}

const FastOrderFormComponent: FC<FastOrderFormPropsI> = () => {
  const M = useMaterialize();
  useEffect(() => M.updateTextFields());
  const navigate = useNavigate();

  const [isPurchase, setIsPurchase] = useState(true);
  const [productCategorySuggestions, setProductCategorySuggestions] =
  useState<SuggestionsInputStateI<SearchCategoriesResponseI>>({
    records: [],
    currentRecordIndex: -1,
  });
  const [productCategory, setProductCategory] = useState('');
  const [productName, setProductName] = useState('');
  const [orderText, setOrderText] = useState('');
  const [orderComments, setOrderComments] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [itn, setItn] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [patronymic, setPatronymic] = useState('');

  const [isProductCategoryValid, setIsProductCategoryValid] = useState(false);
  const [isProductNameValid, setIsProductNameValid] = useState(false);
  const [isOrderTextValid, setIsOrderTextValid] = useState(false);
  const [areOrderCommentsValid, setAreOrderCommentsValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(false);
  const [isItnValid, setIsItnValid] = useState(false);
  const [isFirstnameValid, setIsFirstnameValid] = useState(true);
  const [isSurnameValid, setIsSurnameValid] = useState(true);
  const [isPatronymicValid, setIsPatronymicValid] = useState(true);

  const [showError, setShowError] = useState(false);
  const [previousErrorMsg, setPreviousErrorMsg] = useState('');

  // TODO: модалка
  const [srvErr, setSrvErr] = useState('');

  const isValid = isProductCategoryValid && !!productCategory &&
    isProductNameValid && !!productName &&
    isOrderTextValid && !!orderText &&
    // orderComments - может быть пустым
    areOrderCommentsValid &&
    isEmailValid && !!email &&
    isPhoneValid && !!phone &&
    isCompanyNameValid && !!companyName &&
    isItnValid && !!itn &&
    isFirstnameValid && !!firstName &&
    isSurnameValid && !!surname &&
    isPatronymicValid && !!patronymic;

  let errorMsg = 'Нарушен формат полей';
  // orderComments - может быть пустым
  if (!productCategory) {
    errorMsg = 'Пропущено поле Категория товара';
  } else if (!productName) {
    errorMsg = 'Пропущено поле Наименование товара';
  } else if (!orderText) {
    errorMsg = 'Пропущено поле Информация о товаре';
  } else if (!surname) {
    errorMsg = 'Пропущено поле Фамилия';
  } else if (!firstName) {
    errorMsg = 'Пропущено поле Имя';
  } else if (!patronymic) {
    errorMsg = 'Пропущено поле Отчество';
  } else if (!email) {
    errorMsg = 'Пропущено поле Е-маил';
  } else if (!phone) {
    errorMsg = 'Пропущено поле Телефон';
  } else if (!companyName) {
    errorMsg = 'Пропущено поле Название компании';
  } else if (!itn) {
    errorMsg = 'Пропущено поле ИНН';
  }

  // предыдущая ошибка исправлена
  if (showError && errorMsg !== previousErrorMsg) {
    setShowError(false);
  }

  const handleIsPurchase: MouseEventHandler<HTMLSpanElement> = () => {
    setIsPurchase(!isPurchase);
  };

  const submitHandler: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!isValid) {
      setShowError(true);
      setPreviousErrorMsg(errorMsg);
      return;
    }

    Ajax.post({
      url: '/fastOrder',
      body: {
        // продавец 1; покупатель 0
        'role': !isPurchase,
        'product_category': productCategory,
        'product_name': productName,
        'order_text': orderText,
        'order_comments': orderComments,
        'fio': `${surname} ${firstName} ${patronymic}`,
        'email': email,
        'phone': phone.replace(/[-() ]/g, ''),
        'company_name': companyName,
        'itn': itn,
      },
    }).then(({status, response}) => {
      if (status === Ajax.STATUS.ok) {
        // TODO: проработать сущность юзера на бэке
        // store.dispatch(AuthActions.login(response.data as FastRegisterResponseI));
        console.warn('ok');
        // Редирект на заказы
        navigate('/');
      } else {
        console.warn(response.msg);
        setSrvErr(response.msg);
      }
    });
  };

  return (
    <form onSubmit={submitHandler} className='fastorder'>

      <div className='container section'>
        <div className='row'>
          <div className='col s12'>
            <h5>Ваша роль</h5>
          </div>
        </div>

        {/* ///////////////////////////////////////////////////////////////////

                                  Селектор роли

        /////////////////////////////////////////////////////////////////// */}

        <div className='row'>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
          jsx-a11y/no-static-element-interactions */}
          <span className='col s12' onClick={handleIsPurchase}>
            <button
              className={'mr-10 waves-effect waves-light' +
              (isPurchase ? ' btn theme-blue ': ' btn-flat theme-border theme-white')}
              type='button'
            >
              Я хочу купить товар
            </button>
            <button
              className={'waves-effect waves-light' +
              (!isPurchase ? ' btn theme-blue ': ' btn-flat theme-border theme-white')}
              type='button'
            >
              Я хочу продать товар
            </button>
          </span>
        </div>
      </div>

      {/* ///////////////////////////////////////////////////////////////////

                        О товаре: категория, наименование

      /////////////////////////////////////////////////////////////////// */}

      <div className='container section'>
        <div className='row'>
          <div className='col s12'>
            <h5>Информация о товаре</h5>
            <p className='text-secondary'>
              {
                isPurchase ? `
                Расскажите о необходимом товаре или услуге – битуби пришлёт на ваш
                e-mail коммерческие предложения от реальных поставщиков
                ` : `
                Расскажите о Вашем товаре или услуге – битуби свяжет Вас с потенциальными
                покупателями`
              }
            </p>
          </div>
        </div>
        <div className='row mb-0'>
          <div className='col s6'>
            <p>
              <strong>Категория товара</strong>
            </p>
          </div>
          <div className='col s6'>
            <p>
              <strong>Наименование товара</strong>
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col s6'>
            <SuggestionsInput<SearchCategoriesResponseI>
              createRequestPromise={function(query: string): Promise<SearchCategoriesResponseI[]> {
                if (!query) {
                  if (ajaxDebug) console.log('[FastOrder Form => SuggestionsInput] не отправляю пустое поле');
                  return (async () => [])();
                }
                return Ajax.post({
                  url: `/search/categories?skip=0&limit=10`,
                  body: {name: query},
                }, true,
                ).then(({status, response}) => {
                  return status === Ajax.STATUS.ok && (response.data as SearchCategoriesResponseI[]) || [];
                }).catch((err) => {
                  console.warn(err);
                  return [];
                });
              }}
              substituteOnClick={(suggestion) => suggestion.name}
              validatableInputProps = {{
                conditions: fastOrderValidation.productCategory.conditions,
                required: true,
                trimmed: false,
                placeholder: 'Компьютеры',
                id: 'item-category',
                tooltipWidth: '200px',
                validState: [isProductCategoryValid, setIsProductCategoryValid],
              }}
              manageSuggestionsState={[productCategorySuggestions, setProductCategorySuggestions]}
              manageValueState={[productCategory, setProductCategory]}
              RenderOption={CompanyCategoryRenderOption}/>
          </div>
          <div className='col s6'>
            <ValidatableInput
              id='item-name'
              type='text'
              required={true}
              trimmed={false}
              placeholder='Компьютер Mac'
              conditions={fastOrderValidation.productName.conditions}
              valueState={[productName, setProductName]}
              validState={[isProductNameValid, setIsProductNameValid]}
            />
          </div>
        </div>


        {/* ///////////////////////////////////////////////////////////////////

                            Коммерческое предложение
                            Дополнительные сведения

        /////////////////////////////////////////////////////////////////// */}

        <div className='row mb-0'>
          <div className='col s12'>
            <p>
              <strong>Информация о товаре или коммерческое предложение</strong>
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <ValidatableInput
              id='item-order-text'
              type='text'
              placeholder='Оптовая закупка компьютеров в офис'
              textarea={true}
              dataLength={1000}
              trimmed={false}
              required={true}
              conditions={fastOrderValidation.orderText.conditions}
              valueState={[orderText, setOrderText]}
              validState={[isOrderTextValid, setIsOrderTextValid]}
            />
          </div>
        </div>

        <div className='row' />
        <div className='row  mb-0'>
          <div className='col s12'>
            <strong>Дополнительные сведения, важные для сделки</strong>
            <p className='text-secondary'>
              Например, Ваши предпочтения по контрагентам и срокам доставки
            </p>
            <ValidatableInput
              id='item-category'
              type='text'
              placeholder='Сотрудничаю только с ООО'
              dataLength={1000}
              trimmed={false}
              textarea={true}
              conditions={fastOrderValidation.orderText.conditions}
              valueState={[orderComments, setOrderComments]}
              validState={[areOrderCommentsValid, setAreOrderCommentsValid]}
            />
          </div>
        </div>
      </div>

      <div className='row' />


      {/* ///////////////////////////////////////////////////////////////////

                          Секция 2. Контакты фирмы
                                    ФИО

      /////////////////////////////////////////////////////////////////// */}

      <div className='container section'>
        <div className='row'>
          <div className='col s12'>
            <h5>Информация о Вас</h5>
            <p className='text-secondary'>
              Оставьте контакты, чтобы мы могли рассказать Вам о потенциальных&nbsp;
              {isPurchase ? 'партнерах' : 'клиентах'} и представить Вас контрагенту
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <strong>Как к Вам обращаться?</strong>
          </div>
        </div>
        <div className='row'>
          <div className='col s6'>
            <ValidatableInput
              id='Surname'
              labelSign='Фамилия'
              trimmed={true}
              required={true}
              tooltipWidth='200px'
              conditions={regexpValidation.lastName.conditions}
              validState={[isSurnameValid, setIsSurnameValid]}
              valueState={[surname, setSurname]}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col s6'>
            <ValidatableInput
              id='Firstname'
              labelSign='Имя'
              required={true}
              trimmed={true}
              tooltipWidth='200px'
              conditions={regexpValidation.firstName.conditions}
              validState={[isFirstnameValid, setIsFirstnameValid]}
              valueState={[firstName, setFirstName]}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col s6'>
            <ValidatableInput
              id='Patronymic'
              labelSign='Отчество'
              required={true}
              trimmed={true}
              tooltipWidth='200px'
              // у отчества такая же валидация, как у имени
              conditions={regexpValidation.firstName.conditions}
              validState={[isPatronymicValid, setIsPatronymicValid]}
              valueState={[patronymic, setPatronymic]}
            />
          </div>
        </div>

        <div className='row' />

        {/* ///////////////////////////////////////////////////////////////////

                                  емаил, телефон

        /////////////////////////////////////////////////////////////////// */}

        <div className='row'>
          <div className='col s12'>
            <strong>Оставьте контакты для уточнения информации по объявлению</strong>
          </div>
        </div>
        <div className='row'>
          <div className='col s6'>
            <ValidatableInput
              id='actor-email'
              type='text'
              labelSign='E-mail'
              trimmed={true}
              required={true}
              conditions={regexpValidation.email.conditions}
              valueState={[email, setEmail]}
              validState={[isEmailValid, setIsEmailValid]}
            />
          </div>
          <div className='col s6'>
            <ValidatableInput
              id='actor-phone'
              type='text'
              labelSign='Телефон'
              trimmed={true}
              required={true}
              conditions={regexpValidation.phone.conditions}
              valueState={[phone, setPhone]}
              validState={[isPhoneValid, setIsPhoneValid]}
            />
          </div>
        </div>

        {/* ///////////////////////////////////////////////////////////////////

                          Название компании, ИНН

        /////////////////////////////////////////////////////////////////// */}

        <div className='row mb-0'>
          <div className='col s12'>
            <p>
              <strong>Информация о компании</strong>
            </p>
            <p className='text-secondary'>
              Мы дорожим клиентами и хотим быть уверены, что работаем с существующей компанией
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col s6'>
            <ValidatableInput
              id='company-name'
              type='text'
              labelSign='Название компании'
              trimmed={false}
              required={true}
              conditions={regexpValidation.companyName.conditions}
              valueState={[companyName, setCompanyName]}
              validState={[isCompanyNameValid, setIsCompanyNameValid]}
            />
          </div>
          <div className='col s6'>
            <ValidatableInput
              id='company-itn'
              type='text'
              required={true}
              trimmed={true}
              labelSign='ИНН'
              conditions={regexpValidation.itn.conditions}
              valueState={[itn, setItn]}
              validState={[isItnValid, setIsItnValid]}
            />
          </div>
        </div>
      </div>

      {/* ///////////////////////////////////////////////////////////////////

                                Ошибка
                            Кнопка Сабмит

      /////////////////////////////////////////////////////////////////// */}

      {
        !isValid ? <div className='section container row'>
          <div className='col s6 offset-s3'>
            <p className='auth__error-message'>{showError ? errorMsg : ''}</p>
          </div>
        </div> : ''
      }

      <div className='section container row'>
        <div className='col right'>
          <button
            className='btn theme-blue waves-effect waves-light'
            type='submit'
          >
            Регистрация
            <i className='material-icons right'>arrow_forward</i>
          </button>
        </div>
      </div>
    </form>
  );
};

export default FastOrderFormComponent;
