/* eslint-disable */
import React, {FC, useState, useEffect} from 'react';
import RootPage from './rootPage';
import Ajax from '../api/ajax';
import DefaultImg from '../images/default.png';

import {useLocation, useNavigate} from 'react-router';
import './pages.scss';
import {useDispatch} from 'react-redux';
import {getUserMe} from '../redux/reducers/userReducer';
import {getProductID} from '../redux/reducers/productReducer';
import {changeStatusListAction} from '../redux/reducers/chatListReducer';

interface ItemResponseI {
  id: number;
  name: string;
  description: {
    String: string;
    Valid: boolean;
  };
  price: number;
  photo: string;
  company: {
    owner_id: number;
  };
}

type ExistChatResponse = {
  chat_id: number;
  create_new_chat: string;
};

const ItemPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [id, setId] = useState('');
  const [currentUser, setCurrentUser] = useState<any>();
  const [existChat, setExistChat] = React.useState<any>();
  const [itemData, setItemData] = useState({
    id: 0,
    name: '',
    description: {
      String: '',
      Valid: true,
    },
    price: 0,
    photo: '',
    company: {
      owner_id: 0,
    },
  });

  const getId = (location: string) => {
    return location.substring(location.length, location.lastIndexOf('/') + 1);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Ajax.get({
      url: `/user/me`,
    }).then(({status, response}) => {
      if (status === Ajax.STATUS.ok) {
        setCurrentUser(response.data);
        dispatch(getUserMe(response?.data));
        setIsLoading(false);
        setIsAuthorized(true);
        console.log('User new request');
      } else {
        setIsAuthorized(false);
      }
    });
  }, []);

  useEffect(() => {
    Ajax.get({
      url: `/product/${getId(location)}`,
    }).then(({status, response}) => {
      if (status === Ajax.STATUS.ok) {
        setItemData(response.data as ItemResponseI);
        dispatch(getProductID(response?.data));
        console.log(response.data);
      } else {
        console.warn(response.msg);
      }
    });

    if (getId(location) !== undefined && Number.isNaN(getId(location))) {
      console.log(
        '!Number.isNaN(getId(location)',
        Number.isNaN(getId(location)),
        location,
      );
      Ajax.get({
        url: `/product/${getId(location)}`,
      }).then(({status, response}) => {
        if (status === Ajax.STATUS.ok) {
          setItemData(response.data as ItemResponseI);
          console.log(response.data);
        } else {
          console.warn(response.msg);
        }
      });
    }

    if (getId(location) !== undefined) {
      Ajax.get({
        url: `/product/${getId(location)}`,
      }).then(({status, response}) => {
        if (status === Ajax.STATUS.ok) {
          setItemData(response.data as ItemResponseI);
        } else {
          console.warn(response.msg);
        }
      });
    }
  }, [location]);

  const checkChat = () => {
    if (getId(location) !== undefined) {
      Ajax.post({
        url: `/chat/init`,
        body: {
          sender_id: currentUser.id,
          product_id: itemData.id,
        },
      }).then(({status, response}) => {
        if (status === Ajax.STATUS.ok) {
          setExistChat(response.data);
          dispatch(changeStatusListAction(true));
          if ((response?.data as any).create_new_chat == false) {
            navigate(`/chat/${(response?.data as any).chat_id}`);
          } else {
            navigate(`/chat`);
          }

          console.log(response.data);
        } else {
          console.warn('error');
        }
      });
    }
  };
  // console.log('existChat', existChat);
  // console.log('itemData', itemData);
  // console.log('currentUser', currentUser);
  const [numberOfItems, setNumberOfItems] = useState(0);

  const validateNumberOfItems = (n: number): number => {
    if (n < 0) {
      setNumberOfItems(0);
      return 0;
    }
    return n;
  };

  return (
    <RootPage nameOfActive="Item">
      <div className="row theme-blue l-center white-text">
        <h5 className="mv-20">
          Товары и услуги для бизнеса так же просто, как для себя
        </h5>
      </div>
      <div className="container">
        <div className="item">
          <h1 className="item__title">{itemData.name}</h1>
          <div className="item__body">
            <img
              src={
                itemData.photo == ''
                  ? DefaultImg
                  : `${itemData.photo}`.split(',').slice(0, 2).join(',')
              }
              className="item__img"
              alt="не удалось загрузить изображение"
            />
            <div className="item__descr-wrapper">
              <p className="item__descr">
                {itemData.description.String}
                {/* <span className="item__articul">Артикул производителя</span>
                {id} */}
              </p>
              {/* <div className="item__footer"> */}
              {/* <div className="card__counter item-card-counter">
                  <button
                    onClick={() => setNumberOfItems((prevNum) => prevNum - 1)}
                    className="btn theme-blue card__counter-btn"
                  >
                    -
                  </button>
                  {validateNumberOfItems(numberOfItems)}
                  <button
                    onClick={() => setNumberOfItems((prevNum) => prevNum + 1)}
                    className="btn theme-blue card__counter-btn"
                  >
                    +
                  </button>
                </div> */}

              <div className="flex gap-2 w-[400px] flex-col mt-10 mb-2">
                <button
                  // disabled={isLoading}
                  onClick={() =>
                    isAuthorized ? checkChat() : navigate('/register')
                  }
                  style={{width: '100%', marginBottom: '10px'}}
                  className="btn theme-blue"
                >
                  Купить
                </button>
                <button
                  style={{width: '100%'}}
                  className="btn theme-blue"
                  // @ts-ignore
                  onClick={() => navigate(`/company/${itemData.company.id}`)}
                >
                  Посмотреть все объявления продавца
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RootPage>
  );
};

export default ItemPage;
