// Другие регулярки, уникальные для определенных форм лежат
// в src/pages/forms/

export type InputHTML5ValidationAttributesT = {
  pattern: RegExp;
  errorMsg: string;
  validation: {
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    type?: HTMLInputElement['type'];
  };
  conditions: {
    msgOnTrue: string;
    condition: (value: string) => boolean;
  }[];
};

// логин - только буквенные латинские символы и _ числом от 4 до 256
// используется на формах:
// - регистрация
export const login = /^[a-zA-Z0-9_]{4,256}$/;
export const loginSymbols = /^[a-zA-Z0-9_]*$/;

// Любые символы числом от 8 до 128
// используется на формах:
// - регистрация
export const password = /^\S{8,128}$/;
export const passwordSymbols = /^\S*$/;
export const template = /^. {3,256}$/;

// Имена не короче 2-х букв. Отчество (Patronimic) принимаем таким же, хотя русские заканчиваются на 'вна', 'вич'
// используется на формах:
// - регистрация
// - быстрый заказ
export const firstName = /^[a-zA-Zа-яА-ЯЁё]{2,256}$/;
export const firstNameSymbols = /^[a-zA-Zа-яА-ЯЁё]*$/;

// Поддерживает двойные фамилии, апострофы, дефисы. от 2 до 256
// используется на формах:
// - регистрация
// - быстрый заказ
export const lastName =
  /^[a-zA-Zа-яА-ЯёЁ]{1,}'?-?[a-zA-Zа-яА-ЯёЁ]{1,}(\s[a-zA-Zа-яА-ЯёЁ]{1,256})?$/;
export const lastNameSymbols =
  /^[a-zA-Zа-яА-Я]{1,}'?-?[a-zA-Zа-яА-Я]{1,}(\s[a-zA-Zа-яА-Я]+)?$/;

// ФИО - комбинация предыдущих
// используется на формах:
// не используется
// eslint-disable-next-line max-len
export const fio =
  /^[a-zA-Zа-яА-ЯёЁ]{1,}'?-?[a-zA-Zа-яА-ЯёЁ]{1,}(\s[a-zA-Zа-яА-ЯёЁ]{1,256})? [a-zA-Zа-яА-ЯЁё]{2,256}( [a-zA-Zа-яА-ЯЁё]{2,256})?/;

// email html5
// используется на формах:
// - регистрация
// - быстрый заказ
// eslint-disable-next-line max-len
export const email =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

// Полное название компании на сервисе
// https://rosstat.gov.ru/opendata/7708234640-okopf
// используется на формах:
// - регистрация
// - быстрый заказ
export const companyFullName = /[A-ZА-ЯЁ]{2,3} '[a-zA-Zа-яА-ЯЁё]{1,122}'/;
// (ИП|ООО|ПАО) '[a-zA-Zа-яА-ЯЁё]{1,122}'
export const companyFullNameSymbols = /^[a-zA-Zа-яА-ЯЁё\s_\d"]*$/;

export const date = /^[0-9.-\s]*$/;

// Краткое название компании на сервисе
// используется на формах:
// - регистрация
// - быстрый заказ
export const companyName = /^[a-zA-Zа-яА-ЯЁё\s_\d]{3,128}$/;
export const companyNameSymbols = /^[a-zA-Zа-яА-ЯЁё\s_\d]*$/;

// Телефон
// используется на формах:
// - быстрый заказ
export const phone = /^(\+?\d{1,2}\(\d{3}\))\d{3}-\d{2}-\d{2}$/;

// Должность - Краткий текст от 3 (СЕО) до 128. Если составная, то хотя бы первые 2 символа - не пробельные
// используется на формах:
// - регистрация
export const post = /^[a-zA-Zа-яА-ЯёЁ]{2}[a-zA-Zа-яА-ЯёЁ\s]{0,126}$/;
export const postSymbols = /^[a-zA-Zа-яА-ЯёЁ][a-zA-Zа-яА-ЯёЁ\s]*$/;

// ИНН - 10 или 12 цифр
// используется на формах:
// - регистрация
// - быстрый заказ
export const itn = /^[\d{10}|\d{12}]$/;
export const itnSymbols = /^\d{0,12}$/;

// Аналог [а-яёА-ЯЁ]
export const cirillic = /[\u0401\u0451\u0410-\u044f]*/;

export const regexp = {
  login,
  email,
  phone,
  password,
  firstName,
  lastName,

  itn,
  post,
  companyFullName,
  companyName,

  cirillic,
  latinOrCirillic: firstNameSymbols,

  template,
  date,
};

export const regexpValidation: {
  [key in Exclude<
    keyof typeof regexp,
    'cirillic' | 'latinOrCirillic'
  >]: InputHTML5ValidationAttributesT;
} = {
  login: {
    pattern: login,
    errorMsg:
      'Логин - это латинские буквы, цифры и ' +
      'нижнее подчеркивание (_). Длина логина - от 4 символов',
    validation: {
      minLength: 4,
      maxLength: 256,
      required: true,
    },
    conditions: [
      {
        condition: (value) => value.length < 4,
        msgOnTrue: 'Длина логина от 4-х символов',
      },
      {
        condition: (value) => value.length >= 256,
        msgOnTrue: 'Длина логина меньше 256-ти символов',
      },
      {
        condition: (value) => !loginSymbols.test(value),
        msgOnTrue:
          'Используются только латинские буквы, цифры и нижнее подчеркивание (_)',
      },
    ],
  },

  email: {
    pattern: email,
    errorMsg: 'Почта в формате emali@example.com',
    validation: {
      minLength: 5,
      maxLength: 256,
      required: true,
    },
    conditions: [
      {
        msgOnTrue: 'Формат: email@example.com',
        condition: (value) => !email.test(value),
      },
      {
        condition: (value) => {
          const [_, domain] = value.split('@');
          // (1-inf)@(1-63).(1-63)
          return domain ? domain.length > 127 : false;
        },
        msgOnTrue: 'Длина домена меньше 127-ми символов',
      },
      {
        condition: (value) => value.length >= 256,
        msgOnTrue: 'Длина меньше 256-ти символов',
      },
    ],
  },

  phone: {
    pattern: phone,
    errorMsg: 'Формат: +7(123)456-78-90',
    validation: {
      minLength: 15,
      maxLength: 17,
    },
    conditions: [
      {
        condition: (value) => !phone.test(value),
        msgOnTrue: 'Формат: +7(123)456-78-90',
      },
    ],
  },

  password: {
    pattern: password,
    errorMsg: 'Любая комбинация непробельных символов длиной более 8',
    validation: {
      minLength: 8,
      maxLength: 128,
      required: true,
    },
    conditions: [
      {
        condition: (value) => value.length < 8,
        msgOnTrue: 'Длина пароля от 8-х символов',
      },
      {
        condition: (value) => value.length >= 128,
        msgOnTrue: 'Длина пароля меньше 128-ти символов',
      },
      {
        condition: (value) => !passwordSymbols.test(value),
        msgOnTrue: 'Не используются пробельные символы',
      },
    ],
  },

  firstName: {
    pattern: firstName,
    errorMsg: 'Используются только латинские или кириллические буквы',
    validation: {
      minLength: 2,
      maxLength: 256,
    },
    conditions: [
      {
        condition: (value) => !firstNameSymbols.test(value),
        msgOnTrue: 'Используются только латинские или кириллические буквы',
      },
      {
        condition: (value) => value.length < 2 || value.length > 256,
        msgOnTrue: 'Длина от 2-х до 256-ти символов',
      },
    ],
  },

  lastName: {
    pattern: lastName,
    errorMsg: 'Используются только латинские или кириллические буквы, апостроф',
    validation: {
      minLength: 2,
      maxLength: 256,
    },
    conditions: [
      {
        condition: (value) => !lastNameSymbols.test(value),
        msgOnTrue:
          'Используются только латинские или кириллические буквы. Возможны апостроф и дефис',
      },
      {
        condition: (value) => value.length < 2,
        msgOnTrue: 'Длина фамилии от 2-х символов',
      },
      {
        condition: (value) => value.length >= 256,
        msgOnTrue: 'Длина фамилии меньше 256-ти символов',
      },
      {
        condition: (value) =>
          !regexp.latinOrCirillic.test(value.match(/\w/g)?.join('') || ''),
        msgOnTrue: 'Используются только латинские или кириллические буквы',
      },
      {
        condition: (value) => !lastNameSymbols.test(value),
        msgOnTrue: 'Фамилия существует',
      },
    ],
  },

  itn: {
    pattern: itnSymbols,
    errorMsg:
      'ИНН - 10 цифр, присваиваемых при регистрации компании или 12 - для физ. лица',
    validation: {
      minLength: 10,
      maxLength: 12,
    },
    conditions: [
      {
        condition: (value) => value.length !== 10 && value.length !== 12,
        msgOnTrue:
          'ИНН - 10 цифр, присваиваемых при регистрации компании или 12 - для физ. лица',
      },
      {
        condition: (value) => !itnSymbols.test(value),
        msgOnTrue: 'ИНН состоит только из цифр',
      },
    ],
  },

  post: {
    pattern: post,
    errorMsg: 'Кратко о Вашей должности - от 3 до 128 символов',
    validation: {
      minLength: 3,
      maxLength: 128,
    },
    conditions: [
      {
        condition: (value) => !postSymbols.test(value),
        msgOnTrue:
          'Используются только латинские или кириллические буквы, пробелы',
      },
      {
        condition: (value) => value.length > 128 || value.length < 3,
        msgOnTrue: 'Длина от 3 до 128 символов',
      },
      {
        condition: (value) => value.split(' ')[0]?.length < 2,
        msgOnTrue: 'Пробелы разделяют слова и аббревиатуры',
      },
    ],
  },

  companyName: {
    pattern: companyName,
    errorMsg: 'Строка от 3 до 128 символов',
    validation: {
      minLength: 3,
      maxLength: 128,
    },
    conditions: [
      {
        condition: (value) => !companyNameSymbols.test(value),
        msgOnTrue:
          'Используются только буквы, цифры, нижнее подчеркивание (_) или пробелы',
      },
      {
        condition: (value) => value.length > 128 || value.length < 3,
        msgOnTrue: 'Длина от 3 до 128 символов',
      },
    ],
  },

  companyFullName: {
    pattern: companyFullName,
    errorMsg: 'От 3 до 128 букв',
    validation: {
      minLength: 3,
      maxLength: 128,
    },
    conditions: [
      {
        condition: (value) => !companyFullNameSymbols.test(value),
        msgOnTrue:
          'Используются только буквы, цифры, нижнее подчеркивание (_), кавычки (") или пробелы',
      },
      {
        condition: (value) => value.length > 128 || value.length < 3,
        msgOnTrue: 'Длина от 3 до 128 символов',
      },
      {
        condition: (value) => value.split(' ').length < 2,
        msgOnTrue: 'ОПФ и название в кавычках отделены пробелом',
      },
      {
        condition: (value) => !value.match(/".+"/),
        msgOnTrue: 'Название обернуто в двойные кавычки (")',
      },
      {
        condition: (value) => !/[A-ZА-ЯЁ]{2,3}/.test(value.split(' ')[0]),
        msgOnTrue: 'ОПФ представлено аббревиатурой из 2-3 заглавных букв',
      },
    ],
  },

  template: {
    pattern: /^\S$/,
    errorMsg: 'Строка от 3 до 128 символов',
    validation: {
      minLength: 3,
      maxLength: 128, // самая длинная в базе - 85
    },
    conditions: [
      {
        condition: (value) => value.length > 128 || value.length < 3,
        msgOnTrue: 'Длина от 3 до 128 символов',
      },
    ],
  },

  date: {
    pattern: date,
    errorMsg: 'Введите дату в формате 15.09.2004',
    validation: {
      minLength: 1,
      maxLength: 10,
    },
    conditions: [
      {
        condition: (value) => value.length > 10 || value.length < 3,
        msgOnTrue: 'Длина от 3 до 10 символов',
      },
      {
        condition: (value) => !/[0-9.-]/.test(value.split(' ')[0]),
        msgOnTrue: 'Дата должна быть написана через точку',
      },
    ],
  },
};

export const conditionsPlaceholder = [
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    condition: (value: string) => false,
    msgOnTrue: '',
  },
];

export const alwaysErrorPlaceholder = [
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    condition: (value: string) => true,
    msgOnTrue: 'test',
  },
];

export default regexpValidation;
