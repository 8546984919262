import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';
import DefaultImg from '../../images/default.png';

interface INewsCard {
  title: string;
  date: string;
  link: string;
  img: string;
}

const NewsCard: React.FC<INewsCard> = (props) => {
  const {title, date, link, img} = props;
  return (
    <Box
      style={{
        width: '310px',
        height: 'fit-content',
        paddingBottom: '10px',
        backgroundColor: '#F1F4FE',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img
        src={img == '' ? DefaultImg : img}
        alt="newsCard"
        style={{
          marginBottom: '20px',
          borderRadius: '12px 12px 0px 0px',
          width: '330px',
          height: '220px',
        }}
      />
      <Container>
        <Typography
          style={{color: '#707895', fontSize: '11px', marginBottom: '4px'}}
        >
          {date}
        </Typography>
        <Typography
          style={{
            color: '#272121',
            marginBottom: '16px',
            fontSize: '16px',
            fontWeight: 'bold',
            height: '36px',
          }}
        >
          {title}
        </Typography>
        <Button
          style={{backgroundColor: '#558AF0', color: 'white', width: '70px'}}
          href={link}
        >
          Читать
        </Button>
      </Container>
    </Box>
  );
};

export default NewsCard;
