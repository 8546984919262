import React from 'react';
import {InputT} from './InputT';
import {Link} from 'react-router-dom';
import ModalBalanceInput from '../Modals/ModalBalanceInput/ModalBalanceInput';
interface RadioGroupT extends InputT {
  linkOptions: {option: string; link: string}[];
  selectedValue: string;
  onChange: (arg: any) => void;
  imgs: any[];
}
const RadioGroup: React.FC<RadioGroupT> = ({
  linkOptions,
  selectedValue,
  onChange,
  imgs,
}) => {
  const handleRadioChange = (value: string) => {
    onChange(value);
  };

  const [open, setOpen] = React.useState(false);

  function handleOpenPopup() {
    setOpen(true);
  }

  function handleClosePopup() {
    setOpen(false);
  }

  return (
    <>
      <div>
        {linkOptions.map(({option, link}, i) => (
          <Link
            to={link}
            key={i}
            onClick={() => link == '#' && handleOpenPopup()}
          >
            <label key={option} className="cursor-pointer">
              <input
                type="radio"
                value={option}
                checked={selectedValue === option}
                onChange={() => handleRadioChange(option)}
              />
              {selectedValue === option ? (
                <div
                  className="flex p-[10px] gap-[6px] w-[250px] items-center mb-[19px]"
                  style={{borderRadius: '8px', backgroundColor: '#558AF0'}}
                >
                  <img src={imgs[i]} alt="pic" className="fill-white" />
                  <p
                    style={{color: '#FFF', fontSize: '14px', fontWeight: '500'}}
                  >
                    {option}
                  </p>
                </div>
              ) : (
                <div className="flex p-[10px] gap-[6px] w-[250px] items-center mb-[19px]">
                  <img src={imgs[i]} alt="pic" />
                  <p
                    style={{
                      color: '#707895',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    {option}
                  </p>
                </div>
              )}
            </label>
          </Link>
        ))}
      </div>

      <ModalBalanceInput open={open} handleClose={handleClosePopup} />
    </>
  );
};

export default RadioGroup;
