import {Box, Button, Modal} from '@mui/material';
import React from 'react';
import s from './style.module.scss';
import {APIurl} from '../../../utils/globals';
import axios from 'axios';

import closeIcon from '../../../static/landing/addcircle.svg';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function ModalBalanceInput({open, handleClose}: Props) {
  const [balance, setBalance] = React.useState('');
  const [fetchBalance, setFetchBalance] = React.useState<any>(null);

  function handleChangeBalance(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setBalance(e.currentTarget.value);
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    console.log(balance);

    const response = axios
      .post(
        `${APIurl}/payment/addbalance`,
        {
          amount: balance,
        },
        {withCredentials: true},
      )
      .then((res) => {
        setFetchBalance(res.data.data);
        const redirectUrl = res.data.data.confirmation.confirmation_url;

        window.open(redirectUrl, '_blank'); // в новой вкладке
        handleClose();

        // window.location.href = redirectUrl; //в текущей вкладке
      })
      .catch((e) => {
        console.error(e);
      });
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={s.wrapper}>
        <Box className={s.modal__iconClose} onClick={handleClose}>
          <img src={closeIcon} alt="close" />
        </Box>
        <Box component="form" className={s.modal__content}>
          <div className={s.modal__block}>
            <div className={s.modal__title}>Пополните баланс</div>
            <input
              type="text"
              placeholder="Введите сумму в рублях"
              value={balance}
              onChange={(e) => handleChangeBalance(e)}
            />
          </div>
          {fetchBalance && fetchBalance.status == 'pending' && (
            <div className={s.modal__statusPending}>
              Платеж еще не обработан
            </div>
          )}

          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={!(balance !== '' && /^[0-9]+$/.test(balance))}
          >
            Оплатить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
