import * as React from 'react';
import {styled, alpha} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Typography} from '@mui/material';
import dot from '../../../static/imgchat/dot.svg';
import {Box} from '@mui/material';
import {optionsStatus, OptionsStatusType} from './optionsValue';
import {useParams} from 'react-router-dom';
import {APIurl} from '../../../utils/globals';
import {changeStatusListAction} from '../../../redux/reducers/chatListReducer';
import {useAppDispatch} from '../../../redux/hooks';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    '& .MuiMenu-list': {
      boxShadow: `0px 0px 3px 2px rgba(0, 0, 0, 0.07)`,
      background: `#FAFCFF`,
      borderRadius: `12px`,
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ButtonOptions() {
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectStatus = (item: OptionsStatusType) => {
    const request = fetch(`${APIurl}/chat/status`, {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify({
        chat_id: Number(id),
        status: item.text,
      }),
    }).then((res) => {
      if (res.status === 200) {
        dispatch(changeStatusListAction(true));
      }
    });
  };

  return (
    <>
      <Button
        sx={{borderRadius: '100px'}}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        Добавить статус
      </Button>
      <StyledMenu
        sx={{borderRadius: '12px', boxShadow: '#00000012'}}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {optionsStatus.map((item) => {
          return (
            <MenuItem
              onClick={() => handleSelectStatus(item)}
              disableRipple
              key={item.id}
            >
              <Box sx={{display: 'flex', gap: '4px'}}>
                <img src={dot} alt={'точки'} />
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    backgroundColor: item.colorBackground,
                    borderRadius: '100px',
                    padding: '5px 15px 6px 15px',
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
}
