import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import NewsCard from '../../../components/cards_homepage/NewsCard';
import people from '../../../static/homepage/icons/people.svg';
import s from './new.module.scss';

import newsImg3 from '../../../static/profilepage/imgForNews/3.jpg';
import newsImg2 from '../../../static/profilepage/imgForNews/2.jpg';
import newImg1 from '../../../static/categoriesPics/8.jpg';
import newsImg4 from '../../../static/profilepage/imgForNews/4.jpg';
import {Stack} from '@mui/material';

const News: React.FC = (props) => {
  return (
    <Box component="section" className={s.news}>
      <Box
        sx={{
          margin: '0 auto',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Stack
          display="flex"
          flexDirection={'column'}
          alignItems={'flex-start'}
          sx={{width: '1244px'}}
        >
          <Typography
            style={{fontSize: '36px', color: '#272121', fontWeight: 'bolder'}}
          >
            Новости о нас
          </Typography>
          <Typography style={{fontSize: '20px', color: '#707895'}}>
            Актуальные новости и публикации о битуби собраны в одном месте
          </Typography>
        </Stack>

        <Box className={s.news__cards}>
          <NewsCard
            title={'Обзор рынка строительных материалов в России в 2023 году'}
            date={'22 августа 2023'}
            link="https://vc.ru/trade/805190-obzor-rynka-stroitelnyh-materialov-v-rossii-v-2023-godu"
            img={newImg1}
          />
          <NewsCard
            title={'46% россиян изменили подход к покупке стройматериалов'}
            date={'6 декабря 2022'}
            link="https://www.retail.ru/news/46-rossiyan-izmenili-podkhod-k-pokupke-stroymaterialov-6-dekabrya-2022-223412/"
            img={newsImg2}
          />
          <NewsCard
            title={'Чего ожидать от рынка строительных материалов в 2022 году?'}
            date={'23 августа 2023'}
            link="https://optitraderus.ru/articles/chego-ozhidat-ot-rynka-stroitelnyh-materialov-v-2022-godu/"
            img={newsImg3}
          />
          <NewsCard
            title={'Строительство и инфраструктура'}
            date={'25.02.2023'}
            link="https://www.vedomosti.ru/realty/articles/2024/02/09/1019351-developeri-mogut-sokratit-zatrati-na-pokupku-zemelnih-uchastkov"
            img={newsImg4}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default News;
