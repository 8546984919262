import React, {FC, ReactNode} from 'react';
import HeaderComponent from '../components/header/headerComponent';
// import WSNotifComponent from '../components/wsNotificator/wsNotificatorComponent';
import {AppPageNamesT} from '../routing/appRoutingHeaderConfig';

import NoticeBlock from '../components/noticeBlock/noticeBlock';

// //////////////////////////////////////////////////////
//
//            Стандартная страница приложения
//                      с хедером
//
// //////////////////////////////////////////////////////

interface PageProps {
  nameOfActive: AppPageNamesT;
  children?: ReactNode;
}

const RootPage: FC<PageProps> = ({nameOfActive, children}) => (
  <>
    <HeaderComponent />
    {/* ws debug */}
    {/* <WSNotifComponent /> */}
    {children}
    {/* <NoticeBlock decayTimeout={2000} messages={['msg1', 'msg2']}/> */}
  </>
);

export default RootPage;
