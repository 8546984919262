import {Stack, Box} from '@mui/material';
import React from 'react';
import {mockDataParners} from './mockConstants';

export default function ParnersBlock() {
  return (
    <Stack
      component="section"
      sx={{
        maxWidth: '1314px',
        width: '100%',
        heigh: '592px',
        margin: '0 64px',
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {mockDataParners.map((item) => {
        return (
          <Box
            key={item.id}
            sx={{
              display: 'flex',
              gap: '32px',
              marginTop: '10px',
            }}
          >
            <img src={item.icon} alt="логотип партнера" />
          </Box>
        );
      })}
    </Stack>
  );
}
